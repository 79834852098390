import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestInvokerService } from 'src/app/rest.service';
import { ConfigService } from 'src/app/services/config-service';
import { DataCarrierService } from 'src/app/services/data-carrier.service';
import { AuthService } from '../../../../services/firebase/auth.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  public displayName: string;
  public userType: string;
  public entityRole: string;
  public entityType: string;
  api_url: string;
  private _restInvoker: any;

  constructor(private _router: Router, public _dataCarrier: DataCarrierService, private _configService: ConfigService,
   public toster: ToastrService, @Inject(RestInvokerService) restInvoker) {
    this._restInvoker = restInvoker;
  }

  ngOnInit() {
    this.displayName = localStorage.getItem("DisplayName");
    this.userType = localStorage.getItem("type"); //Owner
    this.entityRole = localStorage.getItem("EntityRole"); //Manager
    this.entityType = localStorage.getItem("EntityType"); //Individual

    this.sessionChecker();
  }

  sessionChecker(){
    //Data to be sent to API
    var payload = {};
    this.api_url = "api/user/me/";

    try {
      var propData = this._restInvoker.SendHttpAsync(this.api_url, "GET", payload, null, null, "N", true)
        .subscribe(
          data => {
            //Session is active
          },
          error => {
                        if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
            if (error.status == 400) {
              this._configService.exceptionLogout(null);
            }
          }
        );
    }
    catch (ex) {
      //console.log(ex);
    }
  }

  logout(){
    this._configService.clearSession();
    this.toster.error('You have logged out of the system.');
    this._router.navigate(['/auth/login'], {
      //replaceUrl: true,
      queryParams: {
        action: "logout"
      },
    });
  }

}
