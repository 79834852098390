// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  endpoint: "https://apsmpmaprdus.azurewebsites.net/", //apsmpmadev //"https://apsmpmaprdus.azurewebsites.net/",
  firebase: {
    apiKey: "AIzaSyBhF7OQBdfJbQvqDo_FVvqNV6yDAP2fXPY",
    authDomain: "property-ms.firebaseapp.com",
    databaseURL: "https://property-ms.firebaseio.com",
    projectId: "property-ms",
    storageBucket: "property-ms.appspot.com",
    messagingSenderId: "978428025216",
    appId: "1:978428025216:web:01b5b5dfabab29531ac965",
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
