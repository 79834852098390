<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div>
            <div>
              <a class="logo"  routerLink='/'>
                <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage" style="max-width:15%">
                <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" style="max-width:15%">
              </a>
            </div>
            <div class="login-main">
              <form class="theme-form" [formGroup]="forgotForm">
                <h4>Reset Your Password</h4>
                <div class="form-group">
                  <label class="col-form-label">Enter Your Email</label>
                  <div class="row">
                    <div class="col-md-12">
                      <input class="form-control" type="email" maxlength="200"   required="" placeholder="Please enter your registered email" formControlName="email">
                      <div *ngIf="forgotForm.controls.email.touched && forgotForm.controls.email.errors?.required"
                          class="text text-danger mt-1">
                          Email is required
                      </div>
                      <div *ngIf="forgotForm.controls.email.touched && forgotForm.controls.email.errors?.email"
                          class="text text-danger mt-1">
                          Invalid Email
                      </div>
                    </div>
                    <div class="col-12">
                      <button class="btn btn-primary btn-block m-t-10" type="submit" (click)="resetPassword()">Send</button>
                    </div>
                  </div>
                </div>
                <div *ngIf="isResetSend" class="text-center mt-4 mb-4"><span class="reset-password-link">If don't receive OTP?  <a class="btn-link text-danger" (click)="resetPassword()">Resend</a></span></div>
                <!-- <div class="form-group">
                  <label class="col-form-label pt-0">Enter OTP</label>
                  <div class="row">
                    <div class="col">
                      <input class="form-control text-center opt-text" type="text" value="0" maxlength="1">
                    </div>
                    <div class="col">
                      <input class="form-control text-center opt-text" type="text" value="0" maxlength="1">
                    </div>
                    <div class="col">
                      <input class="form-control text-center opt-text" type="text" value="0" maxlength="1">
                    </div>
                    <div class="col">
                      <input class="form-control text-center opt-text" type="text" value="0" maxlength="1">
                    </div>
                  </div>
                </div> -->
                <!-- <h6 class="mt-4">Create Your Password</h6>
                <div class="form-group">
                  <label class="col-form-label">New Password</label>
                  <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" required="" placeholder="*********">
                  <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                  <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Retype Password</label>
                  <input class="form-control" type="password" name="login[password]" required="" placeholder="*********">
                </div>
                <div class="form-group mb-0">
                  <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox">
                    <label class="text-muted" for="checkbox1">Remember Me</label>
                  </div>
                  <button class="btn btn-primary btn-block" type="submit">Done</button>
                </div> -->
                <p class="mt-4 mb-0">Already have an password?<a class="ml-2" [routerLink]="'/auth/login'">Sign in</a></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
