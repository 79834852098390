import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestInvokerService } from 'src/app/rest.service';
import { AppVariables } from 'src/app/services/app-variables';
import { ConfigService } from 'src/app/services/config-service';
import { DataCarrierService } from 'src/app/services/data-carrier.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invite-password',
  templateUrl: './invite-password.component.html',
  styleUrls: ['./invite-password.component.scss']
})
export class InvitePasswordComponent implements OnInit {

  public show: boolean = false;
  public inviteCode: string;
  public inviteId: string;
  public actionVerify: boolean;
  public verifyType: string;
  public api_url: string;
  public userID: string;
  public email: string;
  public password: string;
  private _restInvoker: any;
  public countries: any[];
  public isTermsChecked: boolean = false;
  inviteForm: any;
  firstName: any;
  lastName: any;
  mobile: any;
  role: any;
  entity: any;
  type: any;
  inviteAction: any;
  linkKey: any;
  otpSet1: boolean;
  otpSet2: boolean;
  otpSet3: boolean;
  otpSet4: boolean;
  public otpEntered: string;
  mobilePhone: any;

  constructor(private _activatedRoute: ActivatedRoute, private _formBuilder: FormBuilder, private _appvariable: AppVariables,
    private _router: Router, public _dataCarrier: DataCarrierService, private _configService: ConfigService,
    @Inject(RestInvokerService) restInvoker, public toster: ToastrService) {
    this._restInvoker = restInvoker;
    this.getCountries();
  }

  ngOnInit() {
    try {
      var key = this._activatedRoute.snapshot.queryParams['key'];
      this.linkKey = atob(key);

      var snapEmail = getQueryVariable(this.linkKey, 'email'); //this._activatedRoute.snapshot.queryParams['email'];
      this.email = snapEmail;

      var inviteCode = getQueryVariable(this.linkKey, 'code'); //this._activatedRoute.snapshot.queryParams['code'];
      this.inviteCode = inviteCode;

      var inviteId = getQueryVariable(this.linkKey, 'uid'); //this._activatedRoute.snapshot.queryParams['uid'];
      this.inviteId = inviteId;

      var OTPType = getQueryVariable(this.linkKey, 'type'); //this._activatedRoute.snapshot.queryParams['type'];
      this.verifyType = OTPType;

      var action = getQueryVariable(this.linkKey, 'action'); //this._activatedRoute.snapshot.queryParams['action'];
      this.inviteAction = action;
    } catch (ex) {
      this._router.navigate(['/auth/login']);
    }

    if (this.inviteAction == "verify" && this.verifyType == "email") {
      this.verifyEmail();
    }else{
      this.validateInvite();
    }
    
    this.actionVerify = false;

    this.inviteForm = this._formBuilder.group({
      countrycode: ['971', [Validators.required]],
      mobile: ['', [Validators.required, Validators.maxLength(10), confirmMobileValidator]],
      checkbox1: [false, Validators.required],
      in1: ['', Validators.required],
      in2: ['', Validators.required],
      in3: ['', Validators.required],
      in4: ['', Validators.required],
      // companyname: ['', [(!this.IsSender?Validators.required:null),Validators.minLength(2),Validators.maxLength(100)]],
    });
  }

  showPassword() {
    this.show = !this.show;
  }

  getCountries(){
    //Data to be sent to API
    var payload = {};
    this.countries = [];
    this.api_url = "api/ref-data/countries/";

    try {
      var propCountries = this._restInvoker.SendHttpAsync(this.api_url, "GET", payload, null, null, "N", true)
        .subscribe(
          data => {
            if (data != null && data != undefined) {
                this.countries = [];
                for(var i=0; i<data.data.length; i++){
                  this.countries.push(data.data[i]);
                }
            } else {
              if (data.Message != null) {
                Swal.fire({
                  title: data.response.Message,
                  icon: 'error',
                  showConfirmButton: false,
                  timer: 6000
                });
                return false;
              }
            }
          },
          error => {
            if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
            if (error.status == 400) {
              this._configService.exceptionLogout(null);
            }
          }
        );
    }
    catch (ex) {
      //console.log(ex);
    }
  }

  verifyEmail() {
    this.api_url = "api/auth/verify-otp";
    var payload = {
      "id": this.inviteId,
      "otp": this.inviteCode,
      "isMobile": false
    };

    var validate = this._restInvoker.SendHttpAsync(this.api_url, "POST", payload, null, null, "N", true)
      .subscribe(
        data => {
          //console.log(data);
          if (data.alreadyUser == undefined) {
            this.toster.success('Your email has been verified. Please login again!');
            localStorage.setItem("emailVerified", "true");
            this._router.navigate(['/auth/login'], {
              queryParams: {
                uid: data.id,
              }
            });
          } else {
            if (data.inviteStatus != "Active") {
              Swal.fire({
                title: 'Oops! Expired Invite',
                text: "Your Invite has been expired. Please contact the Support Team!",
                icon: 'error',
                showConfirmButton: true
              }).then((result) => {
                this._router.navigate(['/auth/login']);
              });
              return false;
            } else {
              this.toster.success('Your email has been verified. Please login again!');
              localStorage.setItem("emailVerified", "true");
              this._router.navigate(['/auth/login']);
            }
          }
        },
        error => {
                      if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
          if (error.status == 400) {
            this._configService.exceptionLogout(null);
            let errorMsg = error.error.message;
            Swal.fire({
              title: 'Oops! Entered data is invalid.',
              text: errorMsg,
              icon: 'error',
              showConfirmButton: true
            }).then((result) => {
              this.inviteForm.reset();
            });
          }
        }
      );
  }

  validateInvite() {
    this.api_url = "api/user/invite/validate/";
    var payload = {
      "email": this.email,
      "code": this.inviteCode,
    };

    var validate = this._restInvoker.SendHttpAsync(this.api_url, "POST", payload, null, null, "N", true)
      .subscribe(
        data => {
          //this.toster.success('Your email has been verified. Please login again!');
          if (data.alreadyUser) {
            this._router.navigate(['/auth/login'], {
              queryParams: {
                uid: data.id,
              }
            });
          } else {
            if (data.inviteStatus != "Active") {
              Swal.fire({
                title: 'Oops! Expired Invite',
                text: "Your Invite has been expired. Please contact the Support Team!",
                icon: 'error',
                showConfirmButton: true
              }).then((result) => {
                this._router.navigate(['/auth/login']);
              });
              return false;
            } else {
              this.firstName = data.firstName;
              this.lastName = data.lastName;
              this.mobile = data.mobile;
              this.type = data.inviteType;
              localStorage.setItem("UserFName", data.firstName);
              localStorage.setItem("UserLName", data.lastName);
              localStorage.setItem("Mobile", data.mobile);
              localStorage.setItem("type", data.type);
            }
          }
        },
        error => {
            if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
          if (error.status == 400) {
            this._configService.exceptionLogout(null);
            let errorMsg = error.error.message;
            Swal.fire({
              title: 'Oops! Entered data is invalid.',
              text: errorMsg,
              icon: 'error',
              showConfirmButton: true
            }).then((result) => {
              this.inviteForm.reset();
              this._router.navigate(['/auth/login']);
            });
          }
        }
      );
  }

  termsChecked() {
    if (!this.inviteForm.get('checkbox1').value) {
      this.isTermsChecked = true;
      this.inviteForm.controls['checkbox1'].setValue(true);
    } else {
      this.isTermsChecked = false;
      this.inviteForm.controls['checkbox1'].setValue(false);
    }
  }

  onRegister() {
    if (!this.isTermsChecked) {
      Swal.fire({
        title: 'Terms and Conditions',
        text: 'Please accept Privacy Policy for creating a new account!',
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'Ok, Got it!'
      }).then((result) => {
        if (result.value) {
          //Proceed as Instructed
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          //Cancel Transaction
          Swal.fire(
            'Cancelled',
            'Current Transaction has been cancelled',
            'error'
          )
        }
      });
      return false;
    } else {
      localStorage.setItem("RememberMe", "N");
      if (this.password == "" || this.password == undefined) {
        Swal.fire(
          'Password Mandatory',
          'Please enter the password and submit!',
          'error'
        );
        return;
      }

      this.api_url = "api/user/invite/validate/";
      var payload = {
        "email": this.email,
        "code": this.inviteCode
      };

      var validate = this._restInvoker.SendHttpAsync(this.api_url, "POST", payload, null, null, "N", true)
        .subscribe(
          data => {
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.mobile = data.mobile;
            this.type = data.inviteType;
            this.inviteId = data.id;
            this.entity = "Individual";
            this.role = "Manager";

            var mobileNo = this.inviteForm.get('mobile').value;
            mobileNo = mobileNo + "";
            while(mobileNo.charAt(0) === '0')
            {
              mobileNo = mobileNo.substring(1);
            }

            let lcontacno = this.inviteForm.get('countrycode').value.replace("+", "") + mobileNo;
            let lreplace = /\-/gi;
            let lrcontactno = lcontacno.replace(lreplace, "");
            this.mobilePhone = lrcontactno.replace(" ", ""); 

            //Data to be sent to API
            var payload = {
              "id": this.inviteId,
              "firstName": this.firstName,
              "lastName": this.lastName,
              "mobile": this.mobilePhone, //this.mobile,
              "role": this.role,
              "entityType": this.entity,
              "type": this.type,
              "email": this.email,
              "password": this.password
            };

            this._dataCarrier.setParamData(this.password);

            this.api_url = "api/auth/register-invite/";

            try {
              var registerUser = this._restInvoker.SendHttpAsync(this.api_url, "POST", payload, null, null, "N", true)
                .subscribe(
                  data => {
                    if (data != null && data != undefined) {
                      if (data.email != null && (data.id != null || data.id != undefined || data.id != "")) {

                        localStorage.setItem("UserId", data.id);
                        localStorage.setItem("UserFName", data.firstName);
                        localStorage.setItem("UserLName", data.lastName);
                        localStorage.setItem("EntityType", data.entityType);
                        localStorage.setItem("EmailId", data.email);
                        localStorage.setItem("Mobile", data.mobile);
                        localStorage.setItem("type", data.type);
                        localStorage.setItem("UserType", (data.type == "Owner" ? "owner" : "tenant"));
                        localStorage.setItem("EntityRole", (data.role != null || data.role != undefined || data.role != "" ? data.role : "Tenant"));   //'Owner';//'Manager';//'Tenant';
                        // localStorage.setItem("emailOTP", data.emailVerificationKey); //HACK
                        // localStorage.setItem("mobileOTP", data.mobileVerificationKey); //HACK

                        this._router.navigate(['/auth/otp'], {
                          queryParams: {
                            action: "verify",
                            type: "mobile",
                            uid: data.id
                          }
                        });
                      } else {
                        if (data.Message != null) {
                          Swal.fire({
                            title: data.response.message,
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 6000
                          });
                          return false;
                        }
                      }
                    } else {
                      this._configService.exceptionLogout("UnknownHack");
                    }
                  },
                  error => {
                                if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
                    if (error.status == 400) {
                      this._configService.exceptionLogout(null);
                      try {
                        if (JSON.parse(error.error.message)[0].PropertyName == "Password") {
                          Swal.fire({
                            title: 'Entered Password is Weak. Please enter a password with 1 uppercase, 1 lowercase, 1 number and a symbol with minimum 8 character length!',
                            icon: 'error',
                            showConfirmButton: true
                          });
                          return false;
                        } else {
                          Swal.fire({
                            title: 'Entered Invalid Data. Please re-enter!',
                            icon: 'error',
                            showConfirmButton: true
                          });
                          return false;
                        }
                      }
                      catch (err) {
                        let errorMsg = error.error.message;
                        Swal.fire({
                          title: 'Oops! Entered data is invalid.',
                          text: errorMsg,
                          icon: 'error',
                          showConfirmButton: true
                        }).then((result) => {
                          this.inviteForm.reset();
                          this.inviteForm.controls['checkbox1'].setValue(false);
                        });
                      }
                    }
                  }
                );
            }
            catch (ex) {
              //console.log(ex);
            }
          },
          error => {
                        if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
            if (error.status == 400) {
              this._configService.exceptionLogout(null);
              let errorMsg = error.error.message;
              Swal.fire({
                title: 'Oops! Entered data is invalid.',
                text: errorMsg,
                icon: 'error',
                showConfirmButton: true
              }).then((result) => {
                this.inviteForm.reset();
                this._router.navigate(['/auth/login']);
              });
            }
          }
        );
    }
  }

  resendOTP() {
    this.api_url = "api/auth/send-otp/";
    var payload = {
      "mobile": localStorage.getItem("Mobile"),
      "isMobile": (this.verifyType != "email" ? true : false)
    }

    var resentOTP = this._restInvoker.SendHttpAsync(this.api_url, "POST", payload, null, null, "N", true)
      .subscribe(
        data => {
          // Swal.fire({
          //   title: "OTP has been resend!",
          //   text: "Mobile: 111111" + ", Email: 111111", //Hack Remove
          //   icon: 'success',
          //   showConfirmButton: false,
          //   timer: 6000
          // });
        },
        error => {
                      if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
          if (error.status == 400) {
            this._configService.exceptionLogout(null);
            let errorMsg = error.error.message;
            Swal.fire({
              title: 'Oops! Entered data is invalid.',
              text: errorMsg,
              icon: 'error',
              showConfirmButton: true
            }).then((result) => {
              this.inviteForm.reset();
              this._router.navigate(['/auth/login']);
            });
          }
        }
      );
  }

  verifyOTP() {
    if(this.otpEntered == "" || this.otpEntered.length<4){
    // if (this.inviteForm.get('in1').value == "" || this.inviteForm.get('in2').value == "" ||
    //   this.inviteForm.get('in3').value == "" || this.inviteForm.get('in4').value == "") {
      return false;
    }
    return true;
  }

  submitOTP() {

    if (!this.verifyOTP()) {
      Swal.fire({
        title: "Invalid OTP!",
        text: "Please enter a valid OTP.",
        icon: 'error',
        showConfirmButton: true
      });
      return false;
    }

    if (this.actionVerify) {
      var payload;
      var recOTP = this.otpEntered; //this.inviteForm.get('in1').value + this.inviteForm.get('in2').value + this.inviteForm.get('in3').value + this.inviteForm.get('in4').value;
      this.api_url = "api/auth/verify-otp/";

      payload = {
        "id": this.userID,
        "otp": recOTP,
        "isMobile": (this.verifyType != "email" ? true : false)
      }

      var registerUser = this._restInvoker.SendHttpAsync(this.api_url, "POST", payload, null, null, "N", true)
        .subscribe(
          data => {
            Swal.fire({
              title: (this.verifyType != "email" ? "Mobile number" : "Email") + " has been verified!",
              icon: 'success',
              showConfirmButton: false,
              timer: 6000
            }).then((result) => {
              this.loginUser(); //Auto Login after OTP verification
              // this._router.navigate(['/auth/login'], {
              //   queryParams: {
              //     action: "verified",
              //     type: (this.verifyType != "email" ? "mobile" : "email"),
              //     uid: data.id
              //   }
              // });
            });
          },
          error => {
                        if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
            if (error.status == 400) {
              this._configService.exceptionLogout(null);
              let errorMsg = error.error.message;
              Swal.fire({
                title: 'Oops! Entered data is invalid.',
                text: errorMsg,
                icon: 'error',
                showConfirmButton: true
              }).then((result) => {
                this.inviteForm.reset();
                this._router.navigate(['/auth/login']);
              });
            }
          }
        );
    } else {
      //Handling Reset Password
    }

  }

  loginUser() {
    //Data to be sent to API
    var payload = {
      "email": this.email,
      "password": this.password
    };

    this.api_url = "api/auth/login/";

    try {
      var loginUser = this._restInvoker.SendHttpAsync(this.api_url, "POST", payload, null, null, "N", true)
        .subscribe(
          data => {
            if (data != null && data != undefined) {
              if (data.email != null && (data.id != null || data.id != undefined || data.id != "")) {
                localStorage.setItem("Token", data.accessToken);
                localStorage.setItem("UserId", data.id);
                localStorage.setItem("UserFName", data.firstName);
                localStorage.setItem("UserLName", data.lastName);
                localStorage.setItem("DisplayName", data.displayName);
                localStorage.setItem("EntityType", data.entityType);
                localStorage.setItem("EmailId", data.email);
                localStorage.setItem("Mobile", data.mobile);
                localStorage.setItem("type", data.type);
                localStorage.setItem("UserType", (data.type == "Owner" ? "owner" : "tenant"));
                localStorage.setItem("EntityRole", data.role);   //'Owner';//'Manager';//'Tenant';
                localStorage.setItem("emailVerified", data.emailVerified);
                localStorage.setItem("mobileVerified", data.mobileVerified);

                this._router.navigate(['/property/owner/dashboard'], {
                  //replaceUrl: true,
                  queryParams: {
                    uid: data.id,
                  }
                });
              } else {
                if (data.Message != null) {
                  Swal.fire({
                    title: data.response.Message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 6000
                  });
                  return false;
                }
              }
            } else {
              this._configService.exceptionLogout("UnknownHack");
            }
          },
          error => {
                        if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
            if (error.status == 400) {
              this._configService.exceptionLogout(null);
              try {
                if (error.error.message != null && error.error.message == "User not active") {
                  this.toster.error('Entered User is not Active.');
                  return false;
                } else if (error.error.message != null) {
                  this.toster.error('You have enter Wrong Email or Password.');
                  return false;
                } else {
                  Swal.fire({
                    title: 'Entered Invalid Data. Please re-enter!',
                    icon: 'error',
                    showConfirmButton: true
                  });
                  return false;
                }
              }
              catch (err) {
                let errorMsg = error.error.message;
                Swal.fire({
                  title: 'Oops! Entered data is invalid.',
                  text: errorMsg,
                  icon: 'error',
                  showConfirmButton: true
                }).then((result) => {
                  this._router.navigate(['/auth/login'], {
                    queryParams: {
                      action: "unverified",
                      type: (this.verifyType != "email" ? "mobile" : "email"),
                    }
                  });
                });
              }
            }
          }
        );
    }
    catch (ex) {
      //console.log(ex);
    }
  }

  otpClick(inno) {
    // if(inno == 1 && this.inviteForm.get('in1').value == 0) this.inviteForm.controls['in1'].setValue("");
    // else if(inno == 2 && this.inviteForm.get('in2').value == 0) this.inviteForm.controls['in2'].setValue("");
    // else if(inno == 3 && this.inviteForm.get('in3').value == 0) this.inviteForm.controls['in3'].setValue("");
    // else if(inno == 4 && this.inviteForm.get('in4').value == 0) this.inviteForm.controls['in4'].setValue("");
  }

  onOtpChange(events){
    this.otpEntered = events;
  }
  
  otpChange(inno){
    if(inno == '1'){
      this.otpSet1 = false;
      this.otpSet2 = true;
      this.otpSet3 = false;
      this.otpSet4 = false;
    }else if(inno == '2'){
      this.otpSet1 = false;
      this.otpSet2 = false;
      this.otpSet3 = true;
      this.otpSet4 = false;
    }else if(inno == '3'){
      this.otpSet1 = false;
      this.otpSet2 = false;
      this.otpSet3 = false;
      this.otpSet4 = true;
    }else if(inno == '4'){
      this.otpSet1 = true;
      this.otpSet2 = false;
      this.otpSet3 = false;
      this.otpSet4 = false;
    }
  }

}

function getQueryVariable(query, variable) {
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  //console.log('Query variable %s not found', variable);
}

/**
 * Confirm Mobile Validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
 export const confirmMobileValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const contactno = control.parent.get('contactno');
  var numbers = /^[0-9]+$/;

  if (!contactno) {
    return null;
  }

  let lcontacno = (((contactno.value).split("-")[1] != "" && (contactno.value).split("-")[1] != null && (contactno.value).split("-")[1] != undefined ? (contactno.value).split("-")[1] : (contactno.value)));

  let lreplace = /\-/gi;
  let lrcontactno = lcontacno.replace(lreplace, "");
  let fcontactno = lrcontactno.replace(" ", "");

  if (fcontactno === '') {
    return null;
  }

  //Validate the Register Number
  var regex = /^(?:50|52|54|55|56|58)\d{7}$/;
  var passkey = fcontactno;

  if (fcontactno.match(numbers)) {
    if (regex.test(passkey)) {
      return null;
    } else {
      return { 'mobileNotMatching': true };
    }
  }

  return { 'mobileNotMatching': true };
};
