<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div>
            <div>
              <a class="logo"  routerLink='/'>
                <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage" style="max-width:15%">
                <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" style="max-width:15%">
              </a>
            </div>
            <div class="login-main">
              <form class="theme-form" name="inviteForm" [formGroup]="inviteForm" novalidate>
                <h4>Hi, You are invited to PROPMINT!</h4>
                <!-- <div class="form-group">
                  <label class="col-form-label">Enter Your Mobile Number</label>
                  <div class="row">
                    <div class="col-md-3">
                      <input class="form-control mb-1" type="text" value="+ 971">
                    </div>
                    <div class="col-md-9">
                      <input class="form-control mb-1" type="tel" value="000-000-0000">
                    </div>
                    <div class="col-12">
                      <button class="btn btn-primary btn-block m-t-10" type="submit">Send</button>
                    </div>
                  </div>
                </div> -->

                <div *ngIf="!actionVerify">
                  <h6 class="mt-4">Set Your Password</h6>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <input id="email" name="email" class="form-control" type="email" maxlength="200"  required="" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="email" disabled>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Mobile</label>
                    <div class="form-row">
                    <div class="col-4">
                      <!-- <input class="form-control mb-1" type="text" value="971" disabled> -->
                      <ng-select name="countrycode" formControlName="countrycode">
                        <ng-option *ngFor="let item of countries; let i = index" [value]="item.dialCode">{{item.dialCode}} - {{item.shortCode}}</ng-option>
                      </ng-select>
                    </div>
                    <div class="col-8">
                      <input class="form-control mb-1" type="number" min=0 oninput="validity.valid||(value='');" autocomplete="off" formControlName="mobile">
                    </div>
                    <span class="text text-danger mt-1" *ngIf="inviteForm.controls.mobile.touched && inviteForm.get('mobile').hasError('required')" style="margin-left: 15px;">
                      Field is required
                    </span>
                    <span class="text text-danger mt-1" style="margin-left: 15px;"  *ngIf="inviteForm.controls.mobile.touched && !inviteForm.get('mobile').hasError('required') && inviteForm.get('mobile').hasError('mobileNotMatching')">
                        Contact Number should be a valid mobile
                    </span>
                    <span class="text text-danger mt-1" style="margin-left: 15px;"
                        *ngIf="inviteForm.controls.mobile.touched && !inviteForm.get('mobile').hasError('required') && inviteForm.get('mobile').hasError('maxlength')">
                        Maximum length for Contact Number should be 10
                    </span>
                  </div>
                  </div>
                  
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <input class="form-control" type="password" name="password" required=""  placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="password">
                  </div>
                </div>

                <div *ngIf="!actionVerify" class="form-group mb-0">
                  <div class="checkbox p-0">
                    <input id="checkbox1" name="checkbox1" type="checkbox" formControlName="checkbox1" (click)="termsChecked();">
                    <label class="text-muted" for="checkbox1">Agree with<a class="ml-2" routerLink='/privacy-policy'>Privacy Policy</a></label>
                  </div>
                  <button class="btn btn-primary btn-block" type="submit" (click)="onRegister()">Confirm Password</button>
                </div>

                <div class="form-group" *ngIf="actionVerify">
                  <label class="col-form-label pt-0">Enter you mobile OTP</label>
                  <div class="row" style="text-align: center;justify-content: center;align-items: center;">
                      <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:4}"></ng-otp-input>
                    <!-- <div class="col">
                      <input class="form-control text-center opt-text" formControlName="in1" type="text" value="" maxlength="1" (click)="otpClick('1')" (change)="otpChange('1')" [focus]="otpSet1">
                    </div>
                    <div class="col">
                      <input class="form-control text-center opt-text" formControlName="in2" type="text" value="" maxlength="1" (click)="otpClick('2')" (change)="otpChange('2')" [focus]="otpSet2">
                    </div>
                    <div class="col">
                      <input class="form-control text-center opt-text" formControlName="in3" type="text" value="" maxlength="1" (click)="otpClick('3')" (change)="otpChange('3')" [focus]="otpSet3">
                    </div>
                    <div class="col">
                      <input class="form-control text-center opt-text" formControlName="in4" type="text" value="" maxlength="1" (click)="otpClick('4')" (change)="otpChange('4')" [focus]="otpSet4">
                    </div> -->
                  </div>
                </div>
                <div *ngIf="actionVerify" class="text-center mt-4 mb-4"><span class="reset-password-link">Resend OTP?  <a class="btn-link text-danger" (click)="resendOTP()">Resend</a></span></div>

                <div *ngIf="actionVerify" class="form-group mb-0">
                  <button class="btn btn-primary btn-block" type="submit" (click)="submitOTP()">Confirm OTP</button>
                </div>
                <p class="mt-4 mb-0">Already have an password?<a class="ml-2" [routerLink]="'/auth/login'">Sign in</a></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
