
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})

export class ConfigService {
    constructor(
        private sanitizer: DomSanitizer, public router: Router, public toster: ToastrService
    ) {

    }

    //Toast
    async GetToasted(_message: string, _duration: number, _position: string = "bottom", _type: string = "default") {
        //position : top,middle,bottom
        //default position : bottom
        if (_position == undefined || _position == null || _position == "")
            _position = 'bottom';
        if (_type == undefined || _type == null || _type == "")
            _type = "default";
        // const toast = await this._toastCtrl.create({
        //     message: _message,
        //     duration: _duration,
        //     showCloseButton: true,
        //     cssClass: _type
        // });
        // toast.present();
    }

    /**
     * Check whether the item is null or empty. Returns true if the string is null or empty
     * @param {any} item the string
     */
    public IsNullOrEmpty(item: any) {
        if (typeof item == 'object' || typeof item == 'undefined' || typeof item == 'function') {
            if (item != null && item != undefined)
                return false;
            return true;
        }
        else if (typeof item == 'string') {
            if (item != null && item.trim() != "" && item != undefined)
                return false;
            return true;
        }
    }

    /**
     * Check whether the item is null or empty. Returns true if the string is null or empty
     * @param {any} item the string
     */
    public IsNullOrEmptyObject(item: any) {
        if (typeof item == 'object' || typeof item == 'undefined' || typeof item == 'function') {
            if (item != null && item != undefined)
                return false;
            return true;
        }
        else if (typeof item == 'string') {
            if (item != null && item.trim() != "" && item != undefined)
                return false;
            return true;
        }
    }

    /**
     * Process message collection. Default : ToastControl
     * @param {any} _msgCollection the collection object
     * @param {string} _type the string
     * @param {string} _title the string
     */
    public ProcessMessageCollection(_msgCollection: any, _type: string = "", _title: string = "") {
        //Format message collection
        var msg = "";
        if (_msgCollection != null && _msgCollection.length > 0) {
            for (var i = 0; i < _msgCollection.length; i++) {
                msg += _msgCollection[i].Description + "\n";
            }
            msg = msg.trim();
        }

        //Display the messages
        switch (_type) {
            case "ALERT":
                //this.ShowAlert(_title, msg);
                break;
            default:
                this.GetToasted(msg, 5000, "bottom");
                break;
        }
    }
    AddItemToSession(key: string, value: any) {
        if (typeof value != "string") {
            let val = JSON.stringify(value);
            sessionStorage.setItem(key, val)
        }
        else sessionStorage.setItem(key, value);
    }

    GetItemFromSession(key: string) {
        return sessionStorage.getItem(key);
    }

    GetObjectFromSession(key: string) {
        return JSON.parse(sessionStorage.getItem(key));
    }

    RemoveItemFromSession(key: string) {
        sessionStorage.removeItem(key);
    }

    ClearSession() {
        localStorage.setItem("RememberMe", "N");
        sessionStorage.clear();
    }

    jsUcfirst(string: string) {
        var str = string.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    formatVBDate(datestring: string) {
        if (!this.IsNullOrEmptyObject(datestring)) {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            let current_datetime = new Date(datestring);
            let formatted_date = current_datetime.getDate() + "-" + months[current_datetime.getMonth()] + "-" + current_datetime.getFullYear() % 100;
            return formatted_date;
        }
        else
            return "";
    }
    formatVBTime(timestring: string) {
        if (!this.IsNullOrEmptyObject(timestring)) {
            var _timeObj = new Date(timestring).toTimeString().split(":", 2);
            return _timeObj[0] + ':' + _timeObj[1]
        }
        else
            return "";
    }
    /**
     *
     * @param item
     */
    public replaceWithBlankIfNull(item : any) {
        return (item ? item : "");
      }

    public GenerateRanCode() {
        var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
        var string_length = 6;
        var randomstring = '';
        for (var i = 0; i < string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum, rnum + 1);
        }
        return randomstring;
    }

    public exceptionLogout(error) {

      if(error == '401'){
        this.clearSession();
        this.router.navigate(['/auth/login']);        
        return false;
      }

      if(error == '500'){
        this.toster.error('Oops. Something went wrong.');
        return false;
      }

      if(error == null){
        return false;
      }

      if(error != null && error != "UnknownHack"){
        //console.log(error);
      }else{
        this.clearSession();
        localStorage.setItem("RememberMe", "N");
        Swal.fire({
            title: 'Oops! Something went wrong.',
            text: 'Please Retry Again or Contact Support!',
            icon: 'error',
            showConfirmButton: true,
            timer: 6000
        });
        this.router.navigate(['/auth/login']);        
        return false;
      }
    }

    public clearSession(){
        localStorage.setItem("Token", "");
        localStorage.setItem("UserId", "");
        localStorage.setItem("UserFName", "");
        localStorage.setItem("UserLName", "");
        localStorage.setItem("DisplayName", "");
        localStorage.setItem("EntityType", "");
        localStorage.setItem("EntityId", "");
        localStorage.setItem("EmailId", "");
        localStorage.setItem("Mobile", "");
        localStorage.setItem("type", "");
        localStorage.setItem("UserType", "");
        localStorage.setItem("EntityRole", "");
        localStorage.setItem("emailVerified", "");
        localStorage.setItem("mobileVerified", "");
        localStorage.setItem("EntityOwner", "");
        localStorage.clear();
        sessionStorage.clear();
    }
}
