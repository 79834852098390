<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo"  routerLink='/'>
              <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage" style="max-width:15%">
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" style="max-width:15%">
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" name="registerForm" [formGroup]="registerForm" novalidate>
              <h4>Create your account</h4>
              <p>Enter your details to create account</p>
              <div class="form-group">
                <label class="col-form-label pt-0">Your Name</label>
                <div class="form-row">
                  <div class="col-6">
                    <input class="form-control" type="text" required="" formControlName="firstName" placeholder="First Name" maxlength="100">
                    <span class="text text-danger mt-1" *ngIf="registerForm.controls.firstName.touched && registerForm.get('firstName').hasError('required')">
                      Field is required
                    </span>
                  </div>
                  <div class="col-6">
                    <input class="form-control" type="text" required="" formControlName="lastName" placeholder="Last Name" maxlength="100">
                    <span class="text text-danger mt-1" *ngIf="registerForm.controls.lastName.touched && registerForm.get('lastName').hasError('required')">
                      Field is required
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Email</label>
                <input class="form-control" type="text" required="" id="email" formControlName="email" placeholder="Email" maxlength="200">
                <span class="text text-danger mt-1" *ngIf="registerForm.controls.email.touched && registerForm.get('email').hasError('required')">
                  Field is required
                </span>
                <span class="text text-danger mt-1" *ngIf="registerForm.controls.email.touched && registerForm.get('email').hasError('email')">
                    Please enter a valid email address
                </span>
              </div>
              <div class="form-group">
                <label class="col-form-label">Mobile</label>
                <div class="form-row">
                <div class="col-4">
                  <!-- <input class="form-control mb-1" type="text" value="971" disabled> -->
                  <ng-select name="countrycode" formControlName="countrycode">
                    <ng-option *ngFor="let item of countries; let i = index" [value]="item.dialCode">{{item.dialCode}} - {{item.shortCode}}</ng-option>
                  </ng-select>
                </div>
                <div class="col-8">
                  <input class="form-control mb-1" type="tel" formControlName="mobile">
                </div>
                <span class="text text-danger mt-1" *ngIf="registerForm.controls.mobile.touched && registerForm.get('mobile').hasError('required')" style="margin-left: 15px;">
                  Field is required
                </span>
                <span class="text text-danger mt-1" style="margin-left: 15px;"  *ngIf="registerForm.controls.mobile.touched && !registerForm.get('mobile').hasError('required') && registerForm.get('mobile').hasError('mobileNotMatching')">
                    Contact Number should be a valid mobile
                </span>
                <span class="text text-danger mt-1" style="margin-left: 15px;"
                    *ngIf="registerForm.controls.mobile.touched && !registerForm.get('mobile').hasError('required') && registerForm.get('mobile').hasError('maxlength')">
                    Maximum length for Contact Number should be 10
                </span>
              </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" formControlName="password" required="" placeholder="">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                <span class="text text-danger mt-1" *ngIf="registerForm.controls.password.touched &&  registerForm.get('password').hasError('required')">
                  Password is required
                </span>
                <span class="text text-danger mt-1" *ngIf="registerForm.controls.password.touched && !registerForm.get('password').hasError('required') && registerForm.get('password').hasError('notValidPassword')" style="font-size: 10px;">
                  Should contain upper case, alpha numberic & special characters
                </span>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" name="checkbox1" formControlName="checkbox1" type="checkbox" (click)="termsChecked();">
                  <label class="text-muted" for="checkbox1">Agree with<a class="ml-2" routerLink='/privacy-policy'>Privacy Policy</a></label>
                </div>
                <button [disabled]="showloader" class="btn btn-primary btn-block" type="button" (click)="onRegister()">Create Account</button>
              </div>
              <!-- <h6 class="text-muted mt-4 or">Or signup with</h6>
              <div class="social mt-4">
                <div class="btn-showcase">
                  <a class="btn btn-light" href="https://www.linkedin.com/login" target="_blank">
                    <app-feather-icons [icon]="'linkedin'" class="txt-linkedin"></app-feather-icons> LinkedIn
                  </a>
                  <a class="btn btn-light" href="https://twitter.com/login?lang=en" target="_blank">
                   <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>twitter
                  </a>
                  <a class="btn btn-light" href="https://www.facebook.com/" target="_blank">
                    <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>facebook
                  </a>
                </div>
              </div> -->
              <p class="mt-4 mb-0">Already have an account?<a class="ml-2" [routerLink]="'/auth/login'">Sign in</a></p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
