<div class="media profile-media">
  <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="">
  <div class="media-body"><span>{{displayName}}</span>
    <p class="mb-0 font-roboto">{{userType}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li><a [routerLink]="'/user/profile'"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <li><a [routerLink]="'/user/noti-settings'"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Notification</span></a></li>
  <!-- <li><a href="#"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li><a (click)="logout()"><app-feather-icons [icon]="'log-out'"></app-feather-icons><span>Logout</span></a></li>
</ul>
