<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink="/">
              <img
                class="img-fluid for-light"
                src="assets/images/logo/login.png"
                alt="looginpage"
                style="max-width: 15%"
              />
              <img
                class="img-fluid for-dark"
                src="assets/images/logo/logo_dark.png"
                alt="looginpage"
                style="max-width: 15%"
              />
            </a>
          </div>
          <div class="login-main">
            <div class="theme-form">
              <h4>Terms and Conditions</h4>
              <div class="form-group">
                <p>
                  Tech Fikra company (“we” or “us”) consider the privacy and
                  protection of our clients' data, both corporate and personal,
                  to be of the utmost importance and we take robust measures
                  across our business to protect the security and integrity of
                  all such information.
                </p>
                <p>
                  However, the law requires us to take particular steps in
                  relation to the processing and protection of personal data. To
                  ensure that you feel confident about providing us with your
                  personal information when communicating with us and using our
                  services, we outline below our practices in relation to the
                  collection and use of personal data. This policy (together
                  with our terms of use and any other documents referred to on
                  it) sets out the basis on which we will collect, use and
                  process personal data. Please read this Privacy Policy
                  carefully to ensure you understand how we handle your personal
                  data; please also check this page from time to time as the
                  Policy may be updated.
                </p>
                <h4>INFORMATION WE MAY COLLECT FROM YOU</h4>
                <p>
                  We may collect and process the following information about
                  you:
                </p>
                <ul>
                  <li>
                    Information you provide by filling in forms on our website
                    (www.techfikra.com and/or other development website related
                    to PROPMINT); and information
                    given in hard copy form or by any other means providing:
                    your name; date of birth; address; interests; marketing
                    preferences; when appropriate your credit information; and
                    any other personal information relevant to the services we
                    provide. This information may be provided when you are
                    registering interest, transacting with us, subscribing to
                    our services or for newsletters or requesting information;
                  </li>
                  <li>
                    Correspondence or other contacts you may have with us;
                  </li>
                  <li>
                    Details of transactions you carry out through our website,
                    and any communications by telephone, in person or by any
                    other means relating to those transactions. This may include
                    recording certain telephone conversations, for example
                    relating to live bidding;
                  </li>
                  <li>
                    Details of your visits to our website including, but not
                    limited to, traffic data, web logs or other communication
                    data and the resources you access.
                  </li>
                </ul>
                <p>
                  The information you provide to us will be used for processing
                  transactions but may also be used for internal data
                  processing, such as compiling statistical information about
                  your interests and the use of our webistes and services. As a
                  service to you, we may also use this information to customize
                  the content we offer on our webistes and services.
                </p>
                <h4>SPECIFIC INFORMATION WE MAY COLLECT</h4>
                <p>
                  We may collect and process the following information obtained
                  from your use of our webistes and services.
                </p>
                <h4>Cookies:</h4>
                <p>
                  A cookie is a simple text file that is stored on your computer
                  or mobile device by a website’s server and only that server
                  will be able to retrieve or read the contents of that cookie.
                  Each cookie is unique to your web browser. It will contain
                  some anonymous information such as a unique identifier and the
                  site name and some digits and numbers. It allows a website to
                  remember things like your preferences. Using our website will
                  result in us placing cookies on your computer in the 3
                  categories set out below.
                </p>

                <ul>
                  <li>
                    Strictly necessary: These cookies are essential to enable
                    you to move around the website and use its features, such as
                    accessing secure areas of the website; many of the functions
                    on our website will not work properly, or at all, if you do
                    not allow these cookies to be used. We may therefore be
                    unable to provide many of the services you may wish to
                    request unless you accept the use of these cookies.
                  </li>

                  <li>
                    Performance: These cookies collect information about how
                    visitors use a website, for instance which pages visitors go
                    to most often. These cookies do not collect information that
                    identifies a visitor: all information these cookies collect
                    is aggregated and is therefore anonymous. The information is
                    only used to improve how the website works.
                  </li>

                  <li>
                    Functionality: These cookies allow the website to remember
                    choices you make (such as your user name, language or the
                    region you are in) and provide enhanced, more personal
                    features (for example, remembering changes you have made to
                    text size and fonts).
                  </li>
                </ul>

                <p>
                  If you reside outside the EU, we also use a fourth type of
                  cookie for Targeting or Advertising:
                </p>

                <ul>
                  <li>
                    Targeting or Advertising: We sometimes partner with third
                    parties to deliver more relevant advertisements and to
                    compile web statistics. These third parties may use cookies,
                    web beacons and other tracking technology to monitor your
                    visits to both our webistes and services. They monitor your
                    online behavior and identify website usage, helping
                    advertisers show you advertising and content that is more
                    relevant to you. Some examples of what Targeting and
                    Advertising Cookies do:
                  </li>

                  <li>
                    Provide advertisers with information about your visit to our
                    websites so they can show you advertising that is relevant
                    to you.
                  </li>

                  <li>Limit the number of times you see an advertisement.</li>

                  <li>
                    Help measure the effectiveness of an advertising campaign.
                  </li>
                </ul>
                <p>
                  Don't worry - none of these cookies store personally
                  identifiable information and we only use trusted advertising
                  partners. Just so you know, these third parties will have
                  their own privacy policies and they may be different from
                  ours.
                </p>

                <h6>IP Address</h6>
                <p>
                  We may collect information about your computer, including your
                  internet address, operating system and browser type when you
                  visit us. Your internet address is used to help identify and
                  gather statistical or demographic information about our
                  customers generally. We also use this information for internal
                  system administration to help diagnose problems with our
                  servers, to administer our website and the customer or user
                  experience.
                </p>

                <h4>HOW IS THIS PERSONAL INFORMATION USED?</h4>
                <p>
                  Tech Fikra and its webistes and services will collect, record,
                  store and use your personal data for clearly specified
                  purposes including but not limited to:
                </p>
                <ul>
                  <li>
                    providing you with information, products or services that
                    you request from us or which we believe would interest you
                    in a way that is targeted and relevant. This could include
                    but is not limited to auctions, private sales, storage
                    services and transportation, valuations, real estate,
                    education and insurance;
                  </li>

                  <li>
                    carrying out our obligations arising from any contracts
                    entered into between you and us;
                  </li>

                  <li>
                    notifying you about changes to our products and services;
                  </li>

                  <li>
                    ensuring that our website is presented in the most effective
                    manner for you and your computer
                  </li>

                  <li>selling or purchasing property or services</li>

                  <li>
                    (unless you object,) advising you of forthcoming sales,
                    events and services.
                  </li>
                </ul>
                <p>
                  We may contact you for the above purposes by telephone call,
                  email, text or SMS messaging and other means.
                </p>

                <h4>SHARING INFORMATION</h4>
                <p>
                  We will not share your personal data with anyone outside other
                  than in accordance with the law and as stated in this Policy.
                  Each of the countries in which we operate has different
                  privacy laws. Our Privacy Policy will reflect those.
                </p>

                <p>
                  We may from time to time however need to disclose your
                  personal data to certain third parties.
                </p>

                <h6>Agents or Contractors</h6>

                <p>
                  We may need to disclose certain personal information to our
                  Agents or Contractors to enable us to process transactions or
                  communicate with you. Where we do so it will be on the basis
                  that these agents/contractors are required to keep the
                  information confidential and secure, and will not use the
                  information for any other purpose than to carry out the
                  services they are performing.
                </p>

                <h6>Anti-Money Laundering and Regulatory Authorities</h6>

                <p>
                  We may need to retain and disclose certain information about
                  you to regulatory authorities and to appropriate agencies to
                  conduct anti-money laundering and trade sanction checks and to
                  assist with fraud prevention, in particular, in compliance
                  with our AML obligations and in relation to trade sanctions.
                  We will disclose this information as is required by law.
                </p>

                <h6>Credit Agencies</h6>

                <p>
                  We may need to disclose certain personal information about you
                  to credit agencies to conduct appropriate checks when you are
                  transacting with us.
                </p>

                <h6>Legal Disclosures</h6>

                <p>
                  While we will not voluntarily disclose your personal data we
                  may disclose such information if we are required to do so by a
                  valid Court Order, if we are lawfully requested by government
                  or law enforcement authorities, if we are required to do so
                  pursuant to other legal processes, or if it becomes necessary
                  to protect our rights or property.
                </p>

                <h4>Contacting You</h4>

                <p>
                  In order to provide you with marketing information about our
                  products and services, we may contact you by email, text, SMS
                  or any other messaging service, telephone, fax and by post. At
                  the bottom of any marketing emails you will have a clearly
                  marked unsubscribe option from future mailings. In addition,
                  you may at any time send an email to info@techfikra.com asking
                  for your name to be removed from our lists.
                </p>

                <h4>Your Rights</h4>

                <p>
                  You have the legal right to a copy of the information which we
                  hold about you, including information on how this will be used
                  and to ensure this information is accurate. You also have the
                  right to ask us not to process your personal data for
                  marketing purposes - you can exercise this right by checking
                  certain boxes on the forms we use to collect data or at any
                  time by contacting us at info@techfikra.com.
                </p>

                <h4>Security</h4>

                <p>
                  We will take all reasonable and appropriate steps to protect
                  the security and integrity of all personal information
                  provided to our website, or by any other means electronic or
                  otherwise.
                </p>

                <p>
                  We will ensure that your personal data is suitably protected
                  against both unauthorized physical and electronic access.
                  These measures include an electronic firewall and various
                  other protection measures that involve virus scanning,
                  installation of security patches, vulnerability testing,
                  backup and recovery planning, employee training, security
                  audits and other steps designed constantly to improve the data
                  protection posture. However, we cannot guarantee that
                  information during transmission through the internet or any
                  computer network is entirely safe from unauthorized intrusion,
                  access or manipulation. We will have no liability for
                  disclosure of information due to errors or unauthorized acts
                  of third parties. In any event, where we believe personal data
                  has been compromised, we will notify you of this.
                </p>

                <h4>Third-Party Websites</h4>

                <p>
                  Our sites contain links to other websites which are not
                  operated by us. The information you provide to us will not be
                  transmitted to other websites. However, these websites may
                  collect personal information about you in accordance with
                  their own privacy policies. We is not responsible for the
                  privacy practices or the content of such websites.
                </p>

                <h4>How long will we keep my personal information?</h4>

                <p>
                  We will hold your information on our systems for as long as is
                  necessary for the relevant activity, or for as long as is
                  specified in any agreement between you and us.
                </p>

                <h4>Changes to this Policy</h4>

                <p>
                  We reserve the right to amend or update this Privacy Policy
                  and any of our privacy practices at any time. We will notify
                  users of this website by posting any updated policy on this
                  page and such changes will be effective immediately and
                  without further notice. Where appropriate, we may notify you
                  directly of changes to the Privacy Policy either through email
                  or a prominent notice on our website.
                </p>

                <h4>Contact</h4>

                <p>
                  If you have any queries in relation to the use and protection
                  of your personal data please contact us at info@techfikra.com.
                </p>
              </div>

              <div class="form-group mb-0">
                <h6 class="text-muted mt-4 or">Or Know More?</h6>
                <div class="social mt-4">
                  <div class="btn-showcase">
                    <a class="btn btn-light">
                      <app-feather-icons
                        [icon]="'facebook'"
                        class="txt-fb"
                      ></app-feather-icons
                      >Facebook
                    </a>
                    <a class="btn btn-light">
                      <app-feather-icons
                        [icon]="'twitter'"
                        class="txt-twitter"
                      ></app-feather-icons
                      >twitter
                    </a>
                    <a class="btn btn-light" style="float: right;"  routerLink='/auth/register'>Create Account
                      <app-feather-icons
                        [icon]="'log-in'"
                        class="txt-google"
                      ></app-feather-icons
                      >
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
