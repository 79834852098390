import { Injectable } from '@angular/core';
import { RouterModule, Router, Routes } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { AppVariables } from './services/app-variables';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class RestInvokerService {
  constructor(private _appvariable: AppVariables,private httpClient: HttpClient, private _router: Router, public toster: ToastrService) { }

  /*
  * Factory method which processes async web service calls
  * @param {*} _url path for the ajax call
  * @param {*} _method type of http method used
  * @param {*} _data data posted to service, use null if no data
  * @param {*} _success call back method for success
  * @param {*} _error call back method for error
  * @param {*} _isEncode if true, send data as base64 encoded string
  */

  //Because we are not using a type checker, so the response should be extracted.
  //This function extract it.
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  SendHttpAsync(_url, _method, _data, _success, _error, _isEncode, _isHandShake, _docUpload, _file): any {
    try {
      const formData: FormData = new FormData();
      var url = this._appvariable.EndPoint + _url; //parent.window['options'].BaseURL() + _url;
      //default encode flag always
      if (_isEncode == null || _isEncode == undefined) {
        _isEncode = "N";
      }

      let httpHeaders = null;

      //Set encoding for the request
      if (_isEncode == "Y") {
        if (_data != null) {
          _data.UserId = localStorage.getItem("UserId"); //parent.window['options'].getUserId();
          _data.VisPortID = localStorage.getItem("VisPortID"); //parent.window['options'].getVisPortId();
          _data.DBIndex = localStorage.getItem("DBIndex"); //parent.window['options'].getDbOrder();
          _data.DocumentType = this.GetQueryStringParams('type');
          _data.Mode = this.GetQueryStringParams('mode');
          _data.DocID = this.GetQueryStringParams('docid');
          _data.EntityCode = this.GetQueryStringParams('entity');
          _data = window.btoa(JSON.stringify(_data));

          httpHeaders = new HttpHeaders();
          httpHeaders = httpHeaders.set("Content-Encoding", "true");
        }
      } else if (_data != null) {
        _data.UserId = localStorage.getItem("UserId"); //parent.window['options'].getUserId();
        _data.VisPortID = localStorage.getItem("VisPortID"); //parent.window['options'].getVisPortId();
        _data.DBIndex = localStorage.getItem("DBIndex"); //parent.window['options'].getDbOrder();
        _data.DocumentType = this.GetQueryStringParams('type');
        _data.Mode = this.GetQueryStringParams('mode');
        _data.DocID = this.GetQueryStringParams('docid');
        _data.EntityCode = this.GetQueryStringParams('entity');

        var encodeFlag = localStorage.getItem("EncodeFlag");
        if (encodeFlag != null && encodeFlag == "Y") {
          _data = window.btoa(JSON.stringify(_data));
        } else {
          _data = JSON.stringify(_data);
        }
      }



      //check whether the call is for handshaking with API
      if (_isHandShake == null || _isHandShake == undefined)
        _isHandShake = false;

      //Send ajax call
      //if (!_isHandShake)
      //{ headers = options.getHeaders; }

      //set token to the header
      var token = localStorage.getItem("Token");

      if (token != null && token != undefined) {
        token = "Bearer " + token;
        if (httpHeaders == null){
          httpHeaders = new HttpHeaders();
        }
        httpHeaders = httpHeaders.set("Authorization", token);
      }

      var options = null;
      try {
        if(_docUpload == true){
          if(_url.includes("api/document/upload/multi")){
            if(_file.length>0){
              for(var i=0; i<_file.length; i++ ){
                formData.append('files', _file[i]);
              }              
            }else{
              formData.append('files', _file[0]);
            }   
          }else{
            if(_url.includes("multi")){
              formData.append('files', _file);
            }else{
              formData.append('file', _file);
            }   
          }                
          formData.append('propertyId', JSON.parse(_data).propertyId);
          if(JSON.parse(_data).leaseId != null && JSON.parse(_data).leaseId != undefined && JSON.parse(_data).leaseId != ""){
            formData.append('leaseId', JSON.parse(_data).leaseId);
          }
          if(JSON.parse(_data).maintenanceId != null && JSON.parse(_data).maintenanceId != undefined && JSON.parse(_data).maintenanceId != ""){
            formData.append('maintenanceId', JSON.parse(_data).maintenanceId);
          }
          if(JSON.parse(_data).announcementId != null && JSON.parse(_data).announcementId != undefined && JSON.parse(_data).announcementId != ""){
            formData.append('announcementId', JSON.parse(_data).announcementId);
          }
          if(JSON.parse(_data).messageId != null && JSON.parse(_data).messageId != undefined && JSON.parse(_data).messageId != ""){
            formData.append('messageId', JSON.parse(_data).messageId);
          }
          formData.append('name', JSON.parse(_data).name);
          formData.append('notes', JSON.parse(_data).notes);
          formData.append('documentType', ((JSON.parse(_data).documentType != null && JSON.parse(_data).documentType != undefined)?JSON.parse(_data).documentType:""));
          _data = formData;
        }else{
          httpHeaders = httpHeaders.set("Content-Type", "application/json");
        }
      } catch (e) {
        httpHeaders = new HttpHeaders();
        if(_docUpload == true){
          formData.append('file', _file);
          formData.append('propertyId', JSON.parse(_data).propertyId);
          if(JSON.parse(_data).leaseId != null && JSON.parse(_data).leaseId != undefined && JSON.parse(_data).leaseId != ""){
            formData.append('leaseId', JSON.parse(_data).leaseId);
          }
          if(JSON.parse(_data).maintenanceId != null && JSON.parse(_data).maintenanceId != undefined && JSON.parse(_data).maintenanceId != ""){
            formData.append('maintenanceId', JSON.parse(_data).maintenanceId);
          }
          formData.append('name', JSON.parse(_data).name);
          formData.append('notes', JSON.parse(_data).notes);
          formData.append('documentType', ((JSON.parse(_data).documentType != null && JSON.parse(_data).documentType != undefined)?JSON.parse(_data).documentType:""));
          _data = formData;
        }else{
          httpHeaders = httpHeaders.set("Content-Type", "application/json");
        }
      }

      if (httpHeaders != null){
        options = { headers: httpHeaders };
      }

      if (_method == "GET") { //HTTP GET
        if (options != null)
          return this.httpClient.get(url, options);
        else
          return this.httpClient.get(url);
      } else if (_method == "POST") { //HTTP POST
        if (options != null)
          return this.httpClient.post<any>(url, _data, options);
        else
          return this.httpClient.post<any>(url, _data);
      } else if (_method == "PUT") { //HTTP PUT
        if (options != null)
          return this.httpClient.put<any>(url, _data, options);
        else
          return this.httpClient.put<any>(url, _data);
      } else if (_method == "DELETE") { //HTTP DELETE
        if (options != null)
          return this.httpClient.delete<any>(url, options);
        else
          return this.httpClient.delete<any>(url);
      } else {
        throwError("Method not defined");
      }

      return null;
    }
    catch (e) {
      //console.log(e);
    }
  }

  /*
  * Pre-processes the response before send to success callback
  * @param {*} callback callback method defined
  */
  ResponsePreProcessor(callback): any {
    return function (response, textStatus, xhr) {
      if (response != null && response != undefined) {
        var encodeFlag = xhr.getResponseHeader("Content-Encoding");
        if (encodeFlag == "True") {
          response = window.atob(response);
          response = JSON.parse(response);
          localStorage.setItem("EncodeFlag", "Y");
        } else {
          localStorage.setItem("EncodeFlag", "N");
        }
        //if (response != null && (response.token != null && response.token != undefined && response.token != ""))
        //  options.SetToken(response.token);
      }
      if (callback != null)
        callback.apply(this, arguments);
    }
  };

  public HandleException(typecode, error, msgtype, message, msgtimer, confirmbtn, confirmact) {
    if(msgtype == "") msgtype = "error";
    //console.log(error);
    try{
      if(error.status = 401){
          this.toster.error('Session Expired. Please login again!');
          this._router.navigate(['/auth/login'], {
              queryParams: {
                  auth: 'sessionExpired',
                  uid: ""
              }
          });
      }else if(error.status = 500){
          Swal.fire({
              title: 'Oops! Something went wrong. Please Contact Administrator!',
              icon: 'error',
              showConfirmButton: false,
              timer: 6000
          });
      }else if(error.status = 400){
          Swal.fire({
              title: (error.message != null) ? error.message : "",
              icon: 'error',
              showConfirmButton: false,
              timer: 6000
          });
      }else if(error.status = 403){
        Swal.fire({
            title: 'Oops! Something went wrong. Please Contact Administrator!',
            icon: 'error',
            showConfirmButton: false,
            timer: 6000
        }).then((result) => {
          this._router.navigate(['/widgets/general']);
        });
    }
  }catch(ex){
      Swal.fire({
          title: 'Oops! Something went wrong. Please Contact Administrator!',
          icon: 'error',
          showConfirmButton: false,
          timer: 6000
      });
  }
  return false;
}

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //console.log('${operation} failed: ${error.message}');

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /*
  * Get query string parameters from URL
  * @param sParam : parameter name
  */
  ///<summary>Get the query string parameter from the request URL</summary>
  ///<param>sParam:the param key used in query string</param>
  private GetQueryStringParams(sParam): any {
    var url = window.location;
    var sPageURL = url.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] == sParam) {
        return sParameterName[1];
      }
    }
    return null;
  }
}

