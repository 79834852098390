<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink="/">
              <img
                class="img-fluid for-light"
                src="assets/images/logo/login.png"
                alt="looginpage"
                style="max-width: 15%"
              />
              <img
                class="img-fluid for-dark"
                src="assets/images/logo/logo_dark.png"
                alt="looginpage"
                style="max-width: 15%"
              />
            </a>
          </div>
          <div class="login-main">
            <div class="theme-form">
              <h4>Privacy Policy</h4>
              <div class="form-group">
                <p>
                  Propmint is committed to protecting your privacy. This policy is designed to ensure your personal details are protected when you deal with us. 
                </p>
                <p>By using Propmint or creating an account, you consent to the data practices prescribed in this Privacy Policy. The Company’s Privacy Policy governs the privacy guidelines for the Propmint. Please note that this policy applies only to the Propmint sites, and not to the sites of other companies or organizations to which we provide links.</p>
                <p>
                  However, the law requires us to take particular steps in
                  relation to the processing and protection of personal data. To
                  ensure that you feel confident about providing us with your
                  personal information when communicating with us and using our
                  services, we outline below our practices in relation to the
                  collection and use of personal data. This policy (together
                  with our terms of use and any other documents referred to on
                  it) sets out the basis on which we will collect, use and
                  process personal data. Please read this Privacy Policy
                  carefully to ensure you understand how we handle your personal
                  data; please also check this page from time to time as the
                  Policy may be updated.
                </p>
                <h4>INFORMATION WE MAY COLLECT FROM YOU</h4>
                <p>
                  We may collect and process the following information about
                  you:
                </p>
                <ul>
                  <li>
                    Information you provide by filling in forms on our website
                    (www.techfikra.com and/or other development website related
                    to PROPMINT); and information
                    given in hard copy form or by any other means providing:
                    your name; date of birth; address; interests; marketing
                    preferences; when appropriate your credit information; and
                    any other personal information relevant to the services we
                    provide. This information may be provided when you are
                    registering interest, transacting with us, subscribing to
                    our services or for newsletters or requesting information;
                  </li>
                  <li>
                    Correspondence or other contacts you may have with us;
                  </li>
                  <li>
                    Details of transactions you carry out through our website,
                    and any communications by telephone, in person or by any
                    other means relating to those transactions. This may include
                    recording certain telephone conversations, for example
                    relating to live bidding;
                  </li>
                  <li>
                    Details of your visits to our website including, but not
                    limited to, traffic data, web logs or other communication
                    data and the resources you access.
                  </li>
                </ul>
                <p>
                  The information you provide to us will be used for processing
                  transactions but may also be used for internal data
                  processing, such as compiling statistical information about
                  your interests and the use of our webistes and services. As a
                  service to you, we may also use this information to customize
                  the content we offer on our webistes and services.
                </p>
                <h4>SPECIFIC INFORMATION WE MAY COLLECT</h4>
                <p>
                  We may collect and process the following information obtained
                  from your use of our webistes and services.
                </p>
                <h4>Cookies:</h4>
                <p>
                  A cookie is a simple text file that is stored on your computer
                  or mobile device by a website’s server and only that server
                  will be able to retrieve or read the contents of that cookie.
                  Each cookie is unique to your web browser. It will contain
                  some anonymous information such as a unique identifier and the
                  site name and some digits and numbers. It allows a website to
                  remember things like your preferences. Using our website will
                  result in us placing cookies on your computer in the 3
                  categories set out below.
                </p>

                <ul>
                  <li>
                    Strictly necessary: These cookies are essential to enable
                    you to move around the website and use its features, such as
                    accessing secure areas of the website; many of the functions
                    on our website will not work properly, or at all, if you do
                    not allow these cookies to be used. We may therefore be
                    unable to provide many of the services you may wish to
                    request unless you accept the use of these cookies.
                  </li>

                  <li>
                    Performance: These cookies collect information about how
                    visitors use a website, for instance which pages visitors go
                    to most often. These cookies do not collect information that
                    identifies a visitor: all information these cookies collect
                    is aggregated and is therefore anonymous. The information is
                    only used to improve how the website works.
                  </li>

                  <li>
                    Functionality: These cookies allow the website to remember
                    choices you make (such as your user name, language or the
                    region you are in) and provide enhanced, more personal
                    features (for example, remembering changes you have made to
                    text size and fonts).
                  </li>
                </ul>

                <p>
                  If you reside outside the EU, we also use a fourth type of
                  cookie for Targeting or Advertising:
                </p>

                <ul>
                  <li>
                    Targeting or Advertising: We sometimes partner with third
                    parties to deliver more relevant advertisements and to
                    compile web statistics. These third parties may use cookies,
                    web beacons and other tracking technology to monitor your
                    visits to both our webistes and services. They monitor your
                    online behavior and identify website usage, helping
                    advertisers show you advertising and content that is more
                    relevant to you. Some examples of what Targeting and
                    Advertising Cookies do:
                  </li>

                  <li>
                    Provide advertisers with information about your visit to our
                    websites so they can show you advertising that is relevant
                    to you.
                  </li>

                  <li>Limit the number of times you see an advertisement.</li>

                  <li>
                    Help measure the effectiveness of an advertising campaign.
                  </li>
                </ul>
                <p>
                  WE MAY USE YOUR PERSONAL INFORMATION TO MARKET THIRD PARTY PRODUCTS AND SERVICES TO YOU. HOWEVER, WE SHALL OBTAIN YOUR PERMISSION PRIOR TO PROVIDING YOUR DETAILS TO SUCH THIRD PARTIES AND EVERY TIME WE EMAIL YOU FOR THIRD PARTY MARKETING PURPOSES, WE WILL GIVE YOU THE CHANCE TO REFUSE ANY SUCH EMAILS IN THE FUTURE.
                </p>
                <p>
                  THIS WEBSITE USES COOKIES, TRACKING PIXELS AND RELATED TECHNOLOGIES. COOKIES ARE SMALL DATA FILES THAT ARE SERVED BY OUR PLATFORM AND STORED ON YOUR DEVICE. OUR SITE USES COOKIES DROPPED BY US OR THIRD PARTIES FOR A VARIETY OF PURPOSES INCLUDING TO OPERATE AND PERSONALIZE THE WEBSITE. ALSO, COOKIES MAY ALSO BE USED TO TRACK HOW YOU USE THE SITE TO TARGET ADS TO YOU ON OTHER WEBSITES.
                </p>

                <h6>IP Address</h6>
                <p>
                  We may collect information about your computer, including your
                  internet address, operating system and browser type when you
                  visit us. Your internet address is used to help identify and
                  gather statistical or demographic information about our
                  customers generally. We also use this information for internal
                  system administration to help diagnose problems with our
                  servers, to administer our website and the customer or user
                  experience.
                </p>

                <h4>HOW IS THIS PERSONAL INFORMATION USED?</h4>
                <p>
                  Tech Fikra and its webistes and services will collect, record,
                  store and use your personal data for clearly specified
                  purposes including but not limited to:
                </p>
                <ul>
                  <li>
                    providing you with information, products or services that
                    you request from us or which we believe would interest you
                    in a way that is targeted and relevant. This could include
                    but is not limited to auctions, private sales, storage
                    services and transportation, valuations, real estate,
                    education and insurance;
                  </li>

                  <li>
                    carrying out our obligations arising from any contracts
                    entered into between you and us;
                  </li>

                  <li>
                    notifying you about changes to our products and services;
                  </li>

                  <li>
                    ensuring that our website is presented in the most effective
                    manner for you and your computer
                  </li>

                  <li>selling or purchasing property or services</li>

                  <li>
                    (unless you object,) advising you of forthcoming sales,
                    events and services.
                  </li>
                </ul>
                <p>
                  We may contact you for the above purposes by telephone call,
                  email, text or SMS messaging and other means.
                </p>

                <h4>SHARING INFORMATION</h4>
                <p>
                  We will not share your personal data with anyone outside other
                  than in accordance with the law and as stated in this Policy.
                  Each of the countries in which we operate has different
                  privacy laws. Our Privacy Policy will reflect those.
                </p>

                <p>
                  We may from time to time however need to disclose your
                  personal data to certain third parties.
                </p>

                <h6>Agents or Contractors</h6>

                <p>
                  We may need to disclose certain personal information to our
                  Agents or Contractors to enable us to process transactions or
                  communicate with you. Where we do so it will be on the basis
                  that these agents/contractors are required to keep the
                  information confidential and secure, and will not use the
                  information for any other purpose than to carry out the
                  services they are performing.
                </p>

                <h6>Anti-Money Laundering and Regulatory Authorities</h6>

                <p>
                  We may need to retain and disclose certain information about
                  you to regulatory authorities and to appropriate agencies to
                  conduct anti-money laundering and trade sanction checks and to
                  assist with fraud prevention, in particular, in compliance
                  with our AML obligations and in relation to trade sanctions.
                  We will disclose this information as is required by law.
                </p>

                <h6>Credit Agencies</h6>

                <p>
                  We may need to disclose certain personal information about you
                  to credit agencies to conduct appropriate checks when you are
                  transacting with us.
                </p>

                <h6>Legal Disclosures</h6>

                <p>
                  While we will not voluntarily disclose your personal data we
                  may disclose such information if we are required to do so by a
                  valid Court Order, if we are lawfully requested by government
                  or law enforcement authorities, if we are required to do so
                  pursuant to other legal processes, or if it becomes necessary
                  to protect our rights or property.
                </p>

                <h4>Contacting You</h4>

                <p>
                  In order to provide you with marketing information about our
                  products and services, we may contact you by email, text, SMS
                  or any other messaging service, telephone, fax and by post. At
                  the bottom of any marketing emails you will have a clearly
                  marked unsubscribe option from future mailings. In addition,
                  you may at any time send an email to info@techfikra.com asking
                  for your name to be removed from our lists.
                </p>

                <h4>Your Rights</h4>

                <p>
                  You have the legal right to a copy of the information which we
                  hold about you, including information on how this will be used
                  and to ensure this information is accurate. You also have the
                  right to ask us not to process your personal data for
                  marketing purposes - you can exercise this right by checking
                  certain boxes on the forms we use to collect data or at any
                  time by contacting us at info@techfikra.com.
                </p>

                <h4>Security</h4>

                <p>
                  We will take all reasonable and appropriate steps to protect
                  the security and integrity of all personal information
                  provided to our website, or by any other means electronic or
                  otherwise.
                </p>

                <p>
                  We will ensure that your personal data is suitably protected
                  against both unauthorized physical and electronic access.
                  These measures include an electronic firewall and various
                  other protection measures that involve virus scanning,
                  installation of security patches, vulnerability testing,
                  backup and recovery planning, employee training, security
                  audits and other steps designed constantly to improve the data
                  protection posture. However, we cannot guarantee that
                  information during transmission through the internet or any
                  computer network is entirely safe from unauthorized intrusion,
                  access or manipulation. We will have no liability for
                  disclosure of information due to errors or unauthorized acts
                  of third parties. In any event, where we believe personal data
                  has been compromised, we will notify you of this.
                </p>

                <h4>Third-Party Websites</h4>

                <p>
                  Our sites contain links to other websites which are not
                  operated by us. The information you provide to us will not be
                  transmitted to other websites. However, these websites may
                  collect personal information about you in accordance with
                  their own privacy policies. We is not responsible for the
                  privacy practices or the content of such websites.
                </p>

                <h4>How long will we keep my personal information?</h4>

                <p>
                  We will hold your information on our systems for as long as is
                  necessary for the relevant activity, or for as long as is
                  specified in any agreement between you and us.
                </p>

                <h4>Changes to this Policy</h4>

                <p>
                  Propmint may update this policy. We will notify you about significant changes in the way we treat personal information by placing a prominent notice on our site.
                </p>

                <h4>REFERRALS</h4>
                <p>
                  When you use the referral features of the website (the “Referral”), we may ask you to provide information such as your email address and your user and/or password information at another website or service. You will be able to choose who we send invitations to and we never store the login information you provide. When your friends receive an invitation you initiated, they will be given the opportunity to become Members, and make referrals to their friends also. If the people you invited to the service do not respond to the initial invitation request, we will attempt to send them additional invitations sometime in the future. We do this because sometimes the initial attempts are not delivered to their inbox and so they never actually saw the initial invitations. We may use the data we collect in connection with these communications for the operation and ongoing maintenance of the Referral Program and the services we offer through the website. We may also automatically receive and record information on our server logs from your browser, which could include your IP address, cookie information, browser information, and the page you request. Except as described in this policy, the Personal Information you provide will be kept confidential and used to support your customer relationship with the Company.
                </p>
                <p>
                  If you contact us, we may keep a record of that correspondence and we may collect your email address for archival purposes but will not use, transfer or sell your email address. 
                </p>

                <h4>Contact</h4>

                <p>
                  If you have any questions/comments about privacy, or wish to stop receiving communications from us, please telephone <a href="tel:971525528368">+971 525528368</a> or <a href="mailto:hello@propmint.com">hello@propmint.com</a>
                </p>
              </div>

              <div class="form-group mb-0">
                <h6 class="text-muted mt-4 or">Or Know More?</h6>
                <div class="social mt-4">
                  <div class="btn-showcase">
                    <a class="btn btn-light">
                      <app-feather-icons
                        [icon]="'facebook'"
                        class="txt-fb"
                      ></app-feather-icons
                      >Facebook
                    </a>
                    <a class="btn btn-light">
                      <app-feather-icons
                        [icon]="'twitter'"
                        class="txt-twitter"
                      ></app-feather-icons
                      >twitter
                    </a>
                    <a class="btn btn-light" style="float: right;"  routerLink='/auth/register'>Create Account
                      <app-feather-icons
                        [icon]="'log-in'"
                        class="txt-google"
                      ></app-feather-icons
                      >
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
