import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestInvokerService } from 'src/app/rest.service';
import { ConfigService } from 'src/app/services/config-service';
import { DataCarrierService } from 'src/app/services/data-carrier.service';
import { NavService, Menu } from '../../../../services/nav.service';

@Component({
  selector: 'app-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss']
})
export class MegaMenuComponent implements OnInit {

  public megaItems: Menu[];
  public levelmenuitems: Menu[];
  displayName: string;
  userType: string;
  entityRole: string;
  entityType: string;

  constructor(public navServices: NavService, private _router: Router, public _dataCarrier: DataCarrierService, private _configService: ConfigService,
    public toster: ToastrService, @Inject(RestInvokerService) restInvoker) {
    this.navServices.megaItems.subscribe(megaItems => this.megaItems = megaItems);
    this.navServices.levelmenuitems.subscribe(levelmenuitems => this.levelmenuitems = levelmenuitems);
  }

  ngOnInit() {
    this.displayName = localStorage.getItem("DisplayName");
    this.userType = localStorage.getItem("type"); //Owner
    this.entityRole = localStorage.getItem("EntityRole"); //Manager
    this.entityType = localStorage.getItem("EntityType"); //Individual
  }

  logout(){
    this._configService.clearSession();
    this.toster.error('You have logged out of the system.');
    this._router.navigate(['/auth/login'], {
      //replaceUrl: true,
      queryParams: {
        action: "logout"
      },
    });
  }

  megaMenuToggle() {
    this.navServices.levelMenu = false;
    this.navServices.megaMenu  = !this.navServices.megaMenu;
    if(window.innerWidth < 991) {
      //this.navServices.collapseSidebar = true;
    }
  }

  levelMenuToggle() {
    this.navServices.megaMenu  = false;
    this.navServices.levelMenu = !this.navServices.levelMenu;
    if(window.innerWidth < 991) {
      //this.navServices.collapseSidebar = true;
    }
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.megaItems.forEach(a => {
        if (this.megaItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }


}
