import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RestInvokerService } from 'src/app/rest.service';
import { AppVariables } from 'src/app/services/app-variables';
import { ConfigService } from 'src/app/services/config-service';
import { DataCarrierService } from 'src/app/services/data-carrier.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OTPComponent implements OnInit {

  public show: boolean = false;
  public otpVerified: boolean = false;
  public actionVerify: boolean;
  public verifyType: boolean;
  public api_url: string;
  public userID: string;
  public email: string;
  public password: string;
  private _restInvoker: any;
  public password1: string;
  public password2: string;
  toster: any;
  otpForm: any;
  public otpSet1: boolean = true;
  public otpSet2: boolean = false;
  public otpSet3: boolean = false;
  public otpSet4: boolean = false;
  public otpEntered: string;


  constructor(private _activatedRoute: ActivatedRoute, private _formBuilder: FormBuilder, private _appvariable: AppVariables,
    private _router: Router, public _dataCarrier: DataCarrierService, private _configService: ConfigService,
    @Inject(RestInvokerService) restInvoker,) {
    this._restInvoker = restInvoker;
  }

  ngOnInit() {
    this.email = localStorage.getItem("EmailId");
    this.password = this._dataCarrier.getParamData();
    var confirmOTPType = this._activatedRoute.snapshot.queryParams['action'];
    this.actionVerify = (confirmOTPType == "verify" ? true : false);

    var OTPType = this._activatedRoute.snapshot.queryParams['type'];
    this.verifyType = (OTPType == "email"? false : true);

    var UserId = this._activatedRoute.snapshot.queryParams['uid'];
    this.userID = UserId;

    this.otpForm = this._formBuilder.group({
      in1: ['', Validators.required],
      in2: ['', Validators.required],
      in3: ['', Validators.required],
      in4: ['', Validators.required],
      // companyname: ['', [(!this.IsSender?Validators.required:null),Validators.minLength(2),Validators.maxLength(100)]],
    });
  }

  showPassword() {
    this.show = !this.show;
  }

  resendOTP(){
    this.api_url = "api/auth/send-otp/";
    var payload = {
      "mobile": localStorage.getItem("Mobile"),
      "email": this.userID,
      "isMobile": (this.verifyType ? true : false)
    }

    var resentOTP = this._restInvoker.SendHttpAsync(this.api_url, "POST", payload, null, null, "N", true)
      .subscribe(
        data => {
          // Swal.fire({
          //   title: "OTP has been resend!",
          //   text: "Mobile: 111111" + ", Email: 111111", //Hack Remove
          //   icon: 'success',
          //   showConfirmButton: false,
          //   timer: 6000
          // });
        },
        error => {
            if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
          if (error.status == 400) {
            this._configService.exceptionLogout(null);
            let errorMsg = error.error.message;
            Swal.fire({
              title: 'Oops! Entered data is invalid.',
              text: errorMsg,
              icon: 'error',
              showConfirmButton: true
            }).then((result) => {
              this.otpForm.reset();
            });
          }
        }
      );
  }

  verifyOTP(){
    //if(this.otpForm.get('in1').value == "" || this.otpForm.get('in2').value == "" || this.otpForm.get('in3').value == "" || this.otpForm.get('in4').value == ""){
    if(this.otpEntered == "" || this.otpEntered.length<4){
      return false;
    }
    return true;
  }

  submitOTP() {

    if(!this.verifyOTP()){
      Swal.fire({
        title: "Invalid OTP!",
        text: "Please enter a valid OTP.",
        icon: 'error',
        showConfirmButton: true
      });
      return false;
    }
    var payload;
    var recOTP = this.otpEntered + ""; //this.otpForm.get('in1').value + this.otpForm.get('in2').value + this.otpForm.get('in3').value + this.otpForm.get('in4').value;

    if (this.actionVerify) {
      this.api_url = "api/auth/verify-otp/";
      payload = {
        "id": this.userID,
        "otp": recOTP,
        "isMobile": (this.verifyType ? true : false)
      }

      var registerUser = this._restInvoker.SendHttpAsync(this.api_url, "POST", payload, null, null, "N", true)
        .subscribe(
          data => {
            Swal.fire({
              title: (this.verifyType ? "Mobile number" : "Email") + " has been verified!",
              icon: 'success',
              showConfirmButton: false,
              timer: 6000
            }).then((result) => {
              this.loginUser(); //Auto Login after OTP verification
              // this._router.navigate(['/auth/login'], {
              //   queryParams: {
              //     action: "verified",
              //     type: (this.verifyType ? "mobile" : "email"),
              //     uid: data.id
              //   }
              // });
            });
          },
          error => {
            if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
            if (error.status == 400) {
              this._configService.exceptionLogout(null);
              let errorMsg = error.error.message;
              Swal.fire({
                title: 'Oops! Entered data is invalid.',
                text: errorMsg,
                icon: 'error',
                showConfirmButton: true
              }).then((result) => {
                this.otpForm.reset();
              });
            }
          }
        );
    } else {
      if(this.password1 != this.password2){
        Swal.fire({
          title: 'Password Mismatch!',
          text: "Confirm password is mismatching with entered password. Please Re-enter.",
          icon: 'error',
          showConfirmButton: true
        }).then((result) => {
          return false;
        });
      }else{
       
      this.api_url = "api/auth/forgot-password";
      payload = {
        "email": this.userID,
        "password": this.password1,
        "otp": recOTP,
        "isMobile": (this.verifyType ? true : false)
      }

      var registerUser = this._restInvoker.SendHttpAsync(this.api_url, "POST", payload, null, null, "N", true)
        .subscribe(
          data => {
            Swal.fire({
              title: " You Account password has been changed!",
              icon: 'success',
              showConfirmButton: false,
              timer: 6000
            }).then((result) => {
              this._router.navigate(['/auth/login'], {
                queryParams: {
                  action: "reset",
                  type: (this.verifyType ? "mobile" : "email"),
                }
              });
            });
          },
          error => {
            if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
            if (error.status == 400) {
              this._configService.exceptionLogout(null);
              let errorMsg = error.error.message;
              Swal.fire({
                title: 'Oops! Entered data is invalid.',
                text: errorMsg,
                icon: 'error',
                showConfirmButton: true
              }).then((result) => {
                this.otpForm.reset();
              });
            }
          }
        ); 
      }
    }

  }

  loginUser() {
    //Data to be sent to API
    var payload = {
      "email": this.email,
      "password": this.password
    };

    this.api_url = "api/auth/login/";

    try {
      var loginUser = this._restInvoker.SendHttpAsync(this.api_url, "POST", payload, null, null, "N", true)
        .subscribe(
          data => {
            if (data != null && data != undefined) {
              if (data.email != null && (data.id != null || data.id != undefined || data.id != "")) {
                localStorage.setItem("Token", data.accessToken);
                localStorage.setItem("UserId", data.id);
                localStorage.setItem("UserFName", data.firstName);
                localStorage.setItem("UserLName", data.lastName);
                localStorage.setItem("DisplayName", data.displayName);
                localStorage.setItem("EntityType", data.entityType);
                localStorage.setItem("EmailId", data.email);
                localStorage.setItem("Mobile", data.mobile);
                localStorage.setItem("type", data.type);
                localStorage.setItem("UserType", (data.type == "Owner" ? "owner" : "tenant"));
                localStorage.setItem("EntityRole", data.role);   //'Owner';//'Manager';//'Tenant';
                localStorage.setItem("emailVerified", data.emailVerified);
                localStorage.setItem("mobileVerified", data.mobileVerified);

                this._router.navigate(['/property/owner/dashboard'], {
                  //replaceUrl: true,
                  queryParams: {
                    uid: data.id,
                  }
                });
              } else {
                if (data.Message != null) {
                  Swal.fire({
                    title: data.response.Message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 6000
                  });
                  return false;
                }
              }
            } else {
              this._configService.exceptionLogout("UnknownHack");
            }
          },
          error => {
            if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
            if (error.status == 400) {
              this._configService.exceptionLogout(null);
              try {
                if (error.error.message != null && error.error.message == "User not active") {
                  this.toster.error('Entered User is not Active.');
                  return false;
                } else if (error.error.message != null) {
                  this.toster.error('You have enter Wrong Email or Password.');
                  return false;
                } else {
                  Swal.fire({
                    title: 'Entered Invalid Data. Please re-enter!',
                    icon: 'error',
                    showConfirmButton: true
                  });
                  return false;
                }
              }
              catch (err) {
                let errorMsg = error.error.message;
                Swal.fire({
                  title: 'Oops! Entered data is invalid.',
                  text: errorMsg,
                  icon: 'error',
                  showConfirmButton: true
                }).then((result) => {
                  this._router.navigate(['/auth/login'], {
                    queryParams: {
                      action: "unverified",
                      type: (this.verifyType ? "mobile" : "email"),
                    }
                  });
                });
              }
            }
          }
        );
    }
    catch (ex) {
      //console.log(ex);
    }
  }

  otpClick(inno) {
    // if(inno == 1 && this.otpForm.get('in1').value == 0) this.otpForm.controls['in1'].setValue("");
    // else if(inno == 2 && this.otpForm.get('in2').value == 0) this.otpForm.controls['in2'].setValue("");
    // else if(inno == 3 && this.otpForm.get('in3').value == 0) this.otpForm.controls['in3'].setValue("");
    // else if(inno == 4 && this.otpForm.get('in4').value == 0) this.otpForm.controls['in4'].setValue("");
  }

  onOtpChange(events){
    this.otpEntered = events;
  }

  otpChange(inno){
    if(inno == '1'){
      this.otpSet1 = false;
      this.otpSet2 = true;
      this.otpSet3 = false;
      this.otpSet4 = false;
    }else if(inno == '2'){
      this.otpSet1 = false;
      this.otpSet2 = false;
      this.otpSet3 = true;
      this.otpSet4 = false;
    }else if(inno == '3'){
      this.otpSet1 = false;
      this.otpSet2 = false;
      this.otpSet3 = false;
      this.otpSet4 = true;
    }else if(inno == '4'){
      this.otpSet1 = true;
      this.otpSet2 = false;
      this.otpSet3 = false;
      this.otpSet4 = false;
    }
  }

}
