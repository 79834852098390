import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";

import { AdminGuard } from './shared/guard/admin.guard';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { OTPComponent } from './auth/otp/otp.component';
import { InvitePasswordComponent } from './auth/invite-password/invite-password.component';

import { TenantSetPasswordComponent } from './auth/tenant-set-password/tenant-set-password.component';
import { TenantLoginComponent } from './auth/tenant-login/tenant-login.component';
import { PoliciesComponent } from './policies/privacy/privacy.component';
import { TermsComponent } from './policies/terms/terms.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'property/owner/default',
    pathMatch: 'full'
  },
  {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path: 'auth/register',
    component: RegisterComponent
  },
  {
    path: 'auth/forgot-password',
    component: ForgetPasswordComponent,
  },
  {
    path: 'auth/otp',
    component: OTPComponent,
  },
  {
    path: 'auth/invite-password',
    component: InvitePasswordComponent
  },
  {
    path: 'auth/reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'auth/tenant-set-password',
    component: TenantSetPasswordComponent,
  },
  {
    path: 'auth/tenant-login',
    component: TenantLoginComponent,
  },
  {
    path: 'privacy-policy',
    component: PoliciesComponent,
  },
  {
    path: 'terms-conditions',
    component: TermsComponent,
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AdminGuard],
    children: content
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [AdminGuard],
    children: full
  },
  {
    path: '**',
    redirectTo: 'property/owner/default',
    // canActivate: [AdminGuard],
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
