import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestInvokerService } from 'src/app/rest.service';
import { AppVariables } from 'src/app/services/app-variables';
import { ConfigService } from 'src/app/services/config-service';
import { DataCarrierService } from 'src/app/services/data-carrier.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  public openNotification: boolean = false;
  private _restInvoker: any;
  notifications: any[];
  api_url: string;
  notiBadge: number;

  constructor(private _appvariable: AppVariables, public _dataCarrier: DataCarrierService,
    private _router: Router, route: ActivatedRoute,
    @Inject(RestInvokerService) restInvoker, private _configService: ConfigService,
    public toster: ToastrService) {
    this._restInvoker = restInvoker;
  }

  ngOnInit() {
    this.getNotifications();
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

  navManage(entityType, id, item){
    if(entityType == "Property"){
      this._dataCarrier.setParamData(id);
      localStorage.setItem("propertyId",id);
      localStorage.setItem("propMaster",id+"|"+item.accessType);
      localStorage.setItem("propName",item.propertyName);
      window.location.href = "/property/owner/property-details/details";
    }else if(entityType == "Transaction"){
      this._dataCarrier.setParamData(id);
      localStorage.setItem("propertyId",id);
      localStorage.setItem("propMaster",id+"|"+item.accessType);
      localStorage.setItem("propName",item.propertyName);
      window.location.href = "/property/owner/property-details/expense";
    }else if(entityType == "Invite"){
      this._dataCarrier.setParamData(id);
      localStorage.setItem("propertyId",id);
      localStorage.setItem("propMaster",id+"|"+item.accessType);
      localStorage.setItem("propName",item.propertyName);
      window.location.href = "/property/owner/property-details/managers";
    }
    else if(entityType == "Maintenance"){
      this._dataCarrier.setParamData(item.maintenanceId);
      localStorage.setItem("propertyMNTId",item.maintenanceId);
      window.location.href = "/property/owner/active-mnt";
      //this._router.navigate(['/property/owner/active-mnt']);
    }else if(entityType == "MaintenanceUpdate"){
      this._dataCarrier.setParamData(item.maintenanceId);
      localStorage.setItem("propertyMNTId",item.maintenanceId);
      window.location.href = "/property/owner/active-mnt";
      //this._router.navigate(['/property/owner/active-mnt']);
    }else if(entityType == "Comment"){
      this._dataCarrier.setParamData(item.maintenanceId);
      localStorage.setItem("propertyMNTId",item.maintenanceId);
      localStorage.setItem("propMasterMNT",item.maintenanceId+"|Comment");
      window.location.href = "/property/owner/active-mnt";
      //this._router.navigate(['/property/owner/active-mnt']);
    }else if(entityType == "Announcement"){
      this._dataCarrier.setParamData(id);
      localStorage.setItem("propertyId",id);
      localStorage.setItem("propMaster",id+"|"+item.accessType);
      localStorage.setItem("propName",item.propertyName);
      window.location.href = "/property/owner/property-details/announcements";
      //this._router.navigate(['/property/owner/active-mnt']);
    }   
  }

  getDayFromDate(item, c) {
    var date = new Date(item);
    if (c == "D") {//Day
      return date.getDate();
    } else if (c == "M") { //Month
      var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      return monthNames[date.getMonth()];
    } else if (c == "Y") { //Year
      return date.getFullYear();
    } else if (c == "Date") { //Date
      return date;
    } else if (c == "NotiDate") { //Date
      return (date.getMonth()+1) + "/" + date.getDate() + "/" +  date.getFullYear();
    }
  }

  viewAll(){
    this._router.navigate(['/user/notifications']);
  }
  
  checkAll() {
    //Data to be sent to API
    var payload = {};
    this.notifications = [];
    this.notiBadge = 0;
    this.api_url = "api/user/notifications";

    try {
      var propData = this._restInvoker.SendHttpAsync(this.api_url, "PUT", payload, null, null, "N", true)
        .subscribe(
          data => {
            this.notifications = [];
            this.notiBadge = 0;
          },
          error => {
                        if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
            if (error.status == 400) {
              this._configService.exceptionLogout(null);
              try {
                if (error.error.message != null && error.error.message == "User not active") {
                  this.toster.error('Entered User is not Active.');
                  return false;
                } else {
                  Swal.fire({
                    title: 'Entered Invalid Data. Please re-enter!',
                    icon: 'error',
                    showConfirmButton: true
                  });
                  return false;
                }
              }
              catch (err) {
                let errorMsg = error.error.message;
                Swal.fire({
                  title: 'Oops! Entered data is invalid.',
                  text: errorMsg,
                  icon: 'error',
                  showConfirmButton: true
                }).then((result) => {
                  //this.loginForm.reset();
                });
              }
            }
          }
        );
    }
    catch (ex) {
      //console.log(ex);
    }
  }

  getNotifications() {
    //Data to be sent to API
    var payload = {};
    this.notifications = [];
    this.notiBadge = 0;
    this.api_url = "api/user/notifications";

    try {
      var propData = this._restInvoker.SendHttpAsync(this.api_url, "GET", payload, null, null, "N", true)
        .subscribe(
          data => {
            if (data != null && data != undefined) {
              this.notifications = data;
              this.notiBadge = data.length;
            }
          },
          error => {
                        if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
            if (error.status == 400) {
              this._configService.exceptionLogout(null);
              try {
                if (error.error.message != null && error.error.message == "User not active") {
                  this.toster.error('Entered User is not Active.');
                  return false;
                } else {
                  Swal.fire({
                    title: 'Entered Invalid Data. Please re-enter!',
                    icon: 'error',
                    showConfirmButton: true
                  });
                  return false;
                }
              }
              catch (err) {
                let errorMsg = error.error.message;
                Swal.fire({
                  title: 'Oops! Entered data is invalid.',
                  text: errorMsg,
                  icon: 'error',
                  showConfirmButton: true
                }).then((result) => {
                  //this.loginForm.reset();
                });
              }
            }
          }
        );
    }
    catch (ex) {
      //console.log(ex);
    }
  }


}
