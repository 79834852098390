import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestInvokerService } from 'src/app/rest.service';
import { AppVariables } from 'src/app/services/app-variables';
import { ConfigService } from 'src/app/services/config-service';
import { DataCarrierService } from 'src/app/services/data-carrier.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  public show: boolean = false;
  private _restInvoker: any;
  private _router: Router;
  public email: string;
  public forgotForm: FormGroup;
  api_url: string;
  isEmailVerify: boolean = true;
  isResetSend: boolean = false;

  constructor(private _appvariable: AppVariables, public _dataCarrier: DataCarrierService,
    private router: Router, private _activatedRoute: ActivatedRoute, private _formBuilder: FormBuilder,
    @Inject(RestInvokerService) restInvoker, private _configService: ConfigService,
    public toster: ToastrService) {
    this._restInvoker = restInvoker;
    this._router = router;
  }

  ngOnInit() {
    var OTPType = this._activatedRoute.snapshot.queryParams['type'];
    this.forgotForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(250)]],
    });
  }

  showPassword() {
    this.show = !this.show;
  }

  resetPassword() {
    this.email = this.forgotForm.get('email').value;
    if(this.email == ""){
      Swal.fire({
        title: 'Oops! Entered data is invalid.',
        text: "Email Address is Mandatory",
        icon: 'error',
        showConfirmButton: true
      })
    }else{
      this.resendOTP();
    }
  }

  resendOTP(){
    this.api_url = "api/auth/send-otp/";
    var payload = {
      "email": this.email,
      "isMobile": (this.isEmailVerify?false:true)
    }

    var sendOTP = this._restInvoker.SendHttpAsync(this.api_url, "POST", payload, null, null, "N", true)
      .subscribe(
        data => {
          this.isResetSend = true;
          this._router.navigate(['/auth/otp'], {
            queryParams: {
              action: "reset",
              type: "email",
              uid: this.email
            }
          });
        },
        error => {
          this.isResetSend = true;
          if (error.status == 401) this._configService.exceptionLogout('401');
          if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
          if (error.status == 400) {
            this._configService.exceptionLogout(null);
            let errorMsg = error.error.message;
            Swal.fire({
              title: 'Oops! Entered data is invalid.',
              text: errorMsg,
              icon: 'error',
              showConfirmButton: true
            }).then((result) => {
              this.email = "";
            });
          }
        }
      );
  }

}
