import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})

export class AppVariables {
    public loginState: boolean;
    public UserId: string;
    public UserName: string;
    public EntityType: string;
    public UserRole: string;
    public Station: string;
    public LoginId: string;
    public EmployeeNo: string;
    public MPINFlag: string;
    public FPReqFlag: string;
    public OfflineModeFlag: string;
    public OfflineValidity: string;
    public ResponseHasErrors: boolean;
    public SortFilterFlag: boolean = false;
    public GlobalDateFormat: string;

    public OTPType: string = "mobile"; //"mobile","email"
    public EndPoint: string = environment.endpoint; //'https://apsmpmadev.azurewebsites.net/'; //https://apsmpmadev.azurewebsites.net/api/address/ping/

    //Debug
    //public EndPoint: string = 'https://localhost:44397/'; //https://localhost:44304//api/senders/ping//
}

export class AppSettings {
    public FPAuthentication: boolean;
    public Appmode: string = "Online";
    public IsOnline: boolean = true;
}

export class DeviceMeta {
    public DeviceId: string = "";
    public IpAddress: string = "";
    public IsTablet: boolean = false;
}

export class AppMasterSettings {
    public MFpEnabled: boolean;
    public MOfflineMode: boolean;
    public MMpinEnabled: boolean;
    public MOfflineValidity: string;
    public MNotiSource: boolean;
    public MDateIntervel: string;
}
export class SortFilterMeta {
    public FromDate: string;
    public ToDate: string;
    public ColumnName: string;
    public Order: string;
    public sourceUrl:string;
}
