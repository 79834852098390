import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AppVariables } from '../../services/app-variables';
import { DataCarrierService } from '../../services/data-carrier.service';
import { ConfigService } from '../../services/config-service';
import { RestInvokerService } from '../../rest.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  public show: boolean = false;
  public isTermsChecked: boolean = false;
  public firstName: string;
  public lastName: string;
  public email: string;
  public mobile: string;
  public password: string;

  public entity: string;
  public role: string;
  public type: string;
  public IsOwner: boolean = true;
  public api_url: string;
  public luid: string;
  private _restInvoker: any;
  public countries: any[];
  public selCode: string;
  showloader: boolean;

  constructor(private _formBuilder: FormBuilder, private _appvariable: AppVariables, private _router: Router, public _dataCarrier: DataCarrierService, private _configService: ConfigService,
    @Inject(RestInvokerService) restInvoker,) {
    this._restInvoker = restInvoker;
    this.getCountries();
  }

  ngOnInit() {
    this.registerForm = this._formBuilder.group({
      checkbox1: [false, Validators.required],
      firstName: ['', [Validators.required, Validators.minLength(1)]],
      lastName: ['', [Validators.required, Validators.minLength(1)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(250)]],
      countrycode: ['971', [Validators.required]],
      mobile: ['', [Validators.required, Validators.maxLength(10), confirmMobileValidator]],
      password: ['', [Validators.required, validPasswordValidator]],
      // companyname: ['', [(!this.IsSender?Validators.required:null),Validators.minLength(2),Validators.maxLength(100)]],
    });
  }

  termsChecked() {
    if (!this.registerForm.get('checkbox1').value) {
      this.isTermsChecked = true;
      this.registerForm.controls['checkbox1'].setValue(true);
    } else {
      this.isTermsChecked = false;
      this.registerForm.controls['checkbox1'].setValue(false);
    }
  }

  getCountries(){
    //Data to be sent to API
    var payload = {};
    this.countries = [];
    this.api_url = "api/ref-data/countries/";

    try {
      var propCountries = this._restInvoker.SendHttpAsync(this.api_url, "GET", payload, null, null, "N", true)
        .subscribe(
          data => {
            if (data != null && data != undefined) {
                this.countries = [];
                for(var i=0; i<data.data.length; i++){
                  this.countries.push(data.data[i]);
                }
            } else {
              if (data.Message != null) {
                Swal.fire({
                  title: data.response.Message,
                  icon: 'error',
                  showConfirmButton: false,
                  timer: 6000
                });
                return false;
              }
            }
          },
          error => {
            if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
            if (error.status == 400) {
              this._configService.exceptionLogout(null);
            }
          }
        );
    }
    catch (ex) {
      //console.log(ex);
    }
  }

  onRegister() {
    if (!this.isTermsChecked) {
      Swal.fire({
        title: 'Terms and Conditions',
        text: 'Please accept Privacy Policy for creating a new account!',
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'Ok, Got it!'
      }).then((result) => {
        if (result.value) {
          //Proceed as Instructed
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          //Cancel Transaction
          Swal.fire(
            'Cancelled',
            'Current Transaction has been cancelled',
            'error'
          )
        }
      });
      return false;
    } else {
      localStorage.setItem("RememberMe", "N");
      if (this.registerForm.invalid) {
        Swal.fire(
          'Invalid Data',
          'Please enter all the input fields and submit!',
          'error'
        );
        return;
      }

      this.showloader = true;

      this.firstName = this.registerForm.get('firstName').value;
      this.lastName = this.registerForm.get('lastName').value;
      this.email = this.registerForm.get('email').value;

      var mobileNo = this.registerForm.get('mobile').value;
      while(mobileNo.charAt(0) === '0')
      {
        mobileNo = mobileNo.substring(1);
      }

      let lcontacno = this.registerForm.get('countrycode').value.replace("+", "") + mobileNo;
      let lreplace = /\-/gi;
      let lrcontactno = lcontacno.replace(lreplace, "");
      this.mobile = lrcontactno.replace(" ", "");      
      this.password = this.registerForm.get('password').value;
      this.entity = "Individual";
      this.role = "Manager";
      this.type = (this.IsOwner == true ? "Owner" : "Manager");

      //Data to be sent to API
      var payload = {
        "firstName": this.firstName,
        "lastName": this.lastName,
        "mobile": this.mobile,
        "role": this.role,
        "entityType": this.entity,
        "type": this.type,
        "email": this.email,
        "password": this.password
      };

      this._dataCarrier.setParamData(this.password);

      this.api_url = "api/auth/register/";

      try {
        var registerUser = this._restInvoker.SendHttpAsync(this.api_url, "POST", payload, null, null, "N", true)
          .subscribe(
            data => {
              if (data != null && data != undefined) {
                this.showloader = false;
                if (data.email != null && (data.id != null || data.id != undefined || data.id != "")) {

                  localStorage.setItem("UserId", data.id);
                  localStorage.setItem("UserFName", data.firstName);
                  localStorage.setItem("UserLName", data.lastName);
                  localStorage.setItem("EntityType", data.entityType);
                  localStorage.setItem("EmailId", data.email);
                  localStorage.setItem("Mobile", data.mobile);
                  localStorage.setItem("type", data.type);
                  localStorage.setItem("UserType", (data.type == "Owner" ? "owner" : "tenant"));
                  localStorage.setItem("EntityRole", (data.role != null || data.role != undefined || data.role != "" ? data.role : "Tenant"));   //'Owner';//'Manager';//'Tenant';
                  // localStorage.setItem("emailOTP", data.emailVerificationKey); //HACK
                  // localStorage.setItem("mobileOTP", data.mobileVerificationKey); //HACK

                  this._router.navigate(['/auth/otp'], {
                    queryParams: {
                      action: "verify",
                      type: "mobile",
                      uid: data.id
                    }
                  });
                } else {
                  if (data.Message != null) {
                    Swal.fire({
                      title: data.response.Message,
                      icon: 'error',
                      showConfirmButton: false,
                      timer: 6000
                    });
                    return false;
                  }
                }
              } else {
                this._configService.exceptionLogout("UnknownHack");
              }
            },
            error => {
              this.showloader = false;
              if (error.status == 401) this._configService.exceptionLogout('401');
              if (error.status == 500) {
                //this._configService.exceptionLogout('500');
                try {
                  if (error.error.message=="Error Occured while sending Otp") {
                    Swal.fire({
                      title: "An error occured while sending OTP, please enter an different mobile number",
                      icon: 'error',
                      showConfirmButton: true
                    });
                    return false;
                  } else {
                    Swal.fire({
                      title: 'Entered Invalid Data. Please re-enter!',
                      icon: 'error',
                      showConfirmButton: true
                    });
                    return false;
                  }
                }
                catch (err) {
                  let errorMsg = error.error.message;
                  Swal.fire({
                    title: 'Oops! Entered data is invalid.',
                    text: errorMsg,
                    icon: 'error',
                    showConfirmButton: true
                  }).then((result) => {
                    this.registerForm.reset();
                    this.registerForm.controls['checkbox1'].setValue(false);
                  });
                }
              }

              if (error.status == 403) this._configService.exceptionLogout('403');
              if (error.status == 400) {
                this._configService.exceptionLogout(null);
                try {
                  if (JSON.parse(error.error.message)[0].PropertyName == "Password") {
                    Swal.fire({
                      title: 'Entered Password is Weak. Please enter a password with 1 uppercase, 1 lowercase, 1 number and a symbol with minimum 8 character length!',
                      icon: 'error',
                      showConfirmButton: true
                    });
                    return false;
                  } else {
                    Swal.fire({
                      title: 'Entered Invalid Data. Please re-enter!',
                      icon: 'error',
                      showConfirmButton: true
                    });
                    return false;
                  }
                }
                catch (err) {
                  let errorMsg = error.error.message;
                  Swal.fire({
                    title: 'Oops! Entered data is invalid.',
                    text: errorMsg,
                    icon: 'error',
                    showConfirmButton: true
                  }).then((result) => {
                    this.registerForm.reset();
                    this.registerForm.controls['checkbox1'].setValue(false);
                  });
                }
              }
            }
          );
      }
      catch (ex) {
        //console.log(ex);
      }
    }
  }

  showPassword() {
    this.show = !this.show;
  }
}

export const validPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');

  if (!password) {
    return null;
  }

  var passkey = password.value;
  var IsValidPassword;
  var regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  if (regex.test(passkey)) {
    IsValidPassword = true;
    return null;
  } else {
    IsValidPassword = false;
    return { 'notValidPassword': true };
  }
};

/**
 * Confirm Mobile Validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmMobileValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const contactno = control.parent.get('contactno');
  var numbers = /^[0-9]+$/;

  if (!contactno) {
    return null;
  }

  let lcontacno = (((contactno.value).split("-")[1] != "" && (contactno.value).split("-")[1] != null && (contactno.value).split("-")[1] != undefined ? (contactno.value).split("-")[1] : (contactno.value)));

  let lreplace = /\-/gi;
  let lrcontactno = lcontacno.replace(lreplace, "");
  let fcontactno = lrcontactno.replace(" ", "");

  if (fcontactno === '') {
    return null;
  }

  //Validate the Register Number
  var regex = /^(?:50|52|54|55|56|58)\d{7}$/;
  var passkey = fcontactno;

  if (fcontactno.match(numbers)) {
    if (regex.test(passkey)) {
      return null;
    } else {
      return { 'mobileNotMatching': true };
    }
  }

  return { 'mobileNotMatching': true };
};
