<div class="notification-box" (click)="toggleNotificationMobile()">
  <app-feather-icons [icon]="'bell'"></app-feather-icons>
  <span class="badge badge-pill badge-secondary">{{notiBadge}}</span>
</div>
<ul class="notification-dropdown onhover-show-div" [class.active]="openNotification" style="max-height: 500px !important;overflow-y: auto !important;">
  <li>
    <app-feather-icons [icon]="'bell'"></app-feather-icons>
    <h6 class="f-18 mb-0">Notitications</h6>
  </li>
  <li class="notitopic"  *ngIf="notiBadge == 0">
    <p style="margin-left: 20px;">
      No new notifications
    </p>
  </li>
  <li class="notitopic" *ngFor="let item of notifications; let i = index" (click)="navManage(item.entityType, item.propertyId, item)">
    <i *ngIf="item.entityType == 'Property'" class="fa fa-home mr-3 font-primary" style="position: absolute;margin-top: 5px;"></i>
    <i *ngIf="item.entityType == 'Invite'" class="fa fa-users mr-3 font-success" style="position: absolute;margin-top: 5px;"></i>
    <i *ngIf="item.entityType == 'Maintenance'" class="fa fa-ticket mr-3 font-danger" style="position: absolute;margin-top: 5px;"></i>
    <i *ngIf="item.entityType == 'MaintenanceUpdate'" class="fa fa-ticket mr-3 font-danger" style="position: absolute;margin-top: 5px;"></i>
    <i *ngIf="item.entityType == 'Transaction'" class="fa fa-institution mr-3 font-info" style="position: absolute;margin-top: 5px;"></i>
    <i *ngIf="item.entityType == 'Comment'" class="fa fa-comments-o mr-3 font-warning" style="position: absolute;margin-top: 5px;"></i>
    <i *ngIf="item.entityType == 'Announcement'" class="fa fa-bullhorn mr-3 font-success" style="position: absolute;margin-top: 5px;"></i>
    <p style="margin-left: 20px;">
      <!-- <span *ngIf="item.entityType == 'Announcement'" style="color:black"> Announcement: </span> -->
      {{item.message}} <span class="pull-right">{{getDayFromDate(item.datetime.split('T')[0], 'NotiDate')}}</span>
    </p>
  </li>
  <li></li>  
  <li *ngIf="notiBadge != 0">
    <div class="row">
      <div class="col-md-6">
        <a class="btn btn-primary" (click)="checkAll()"><i class="fa fa-check-square"></i> Mark All</a>
      </div>
      <div class="col-md-6">
        <a class="btn btn-primary" (click)="viewAll()"><i class="fa fa-dot-circle-o"></i> View All</a>
      </div>
    </div>
  </li>
</ul>