<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div>
            <div>
              <a class="logo"  routerLink='/'>
                <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage" style="max-width:15%">
                <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" style="max-width:15%">
              </a>
            </div>
            <div class="login-main">
              <form class="theme-form" name="otpForm" [formGroup]="otpForm" novalidate>
                <h4>Confirm OTP</h4>
                <!-- <div class="form-group">
                  <label class="col-form-label">Enter Your Mobile Number</label>
                  <div class="row">
                    <div class="col-md-3">
                      <input class="form-control mb-1" type="text" value="+ 971">
                    </div>
                    <div class="col-md-9">
                      <input class="form-control mb-1" type="tel" value="000-000-0000">
                    </div>
                    <div class="col-12">
                      <button class="btn btn-primary btn-block m-t-10" type="submit">Send</button>
                    </div>
                  </div>
                </div> -->
                <div class="form-group">
                  <label class="col-form-label pt-0" *ngIf="verifyType">Check your Mobile for OTP</label>
                  <label class="col-form-label pt-0" *ngIf="!verifyType">Check your Email for OTP</label>
                  <div class="row" style="text-align: center;justify-content: center;align-items: center;">
                    <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:4}"></ng-otp-input>
                    <!-- <div class="col">
                      <input class="form-control text-center opt-text" autocomplete="off" formControlName="in1" type="text" value="" maxlength=1 (click)="otpClick('1')" (change)="otpChange('1')" [focus]="otpSet1">
                    </div>
                    <div class="col">
                      <input class="form-control text-center opt-text" autocomplete="off"  formControlName="in2" type="text" value="" maxlength="1" (click)="otpClick('2')" (change)="otpChange('2')" [focus]="otpSet2">
                    </div>
                    <div class="col">
                      <input class="form-control text-center opt-text" autocomplete="off"  formControlName="in3" type="text" value="" maxlength="1" (click)="otpClick('3')" (change)="otpChange('3')" [focus]="otpSet3">
                    </div>
                    <div class="col">
                      <input class="form-control text-center opt-text" autocomplete="off" formControlName="in4" type="text" value="" maxlength="1" (click)="otpClick('4')" (change)="otpChange('4')" [focus]="otpSet4">
                    </div> -->
                  </div>
                </div>
                <div class="text-center mt-4 mb-4"><span class="reset-password-link">Resend OTP?  <a class="btn-link text-danger" (click)="resendOTP()">Resend</a></span></div>

                <div *ngIf="!actionVerify">
                  <h6 class="mt-4">Reset Your Password</h6>
                  <div class="form-group">
                    <label class="col-form-label">Email</label>
                    <input class="form-control" type="email" id="email" name="email" [ngModelOptions]="{standalone: true}" [(ngModel)]="userID" disabled>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">New Password</label>
                    <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" required="" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="password1">
                    <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                    <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Confirm Password</label>
                    <input class="form-control" type="password" name="login[password]" required="" placeholder="" [ngModelOptions]="{standalone: true}" [(ngModel)]="password2">
                  </div>
                </div>

                <div class="form-group mb-0">
                  <button class="btn btn-primary btn-block" type="submit" (click)="submitOTP()">Confirm</button>
                </div>
                <p class="mt-4 mb-0">Already have an password?<a class="ml-2" [routerLink]="'/auth/login'">Sign in</a></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
