import { Component, OnInit, Inject, HostListener, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { ToastrService } from 'ngx-toastr';
import { RestInvokerService } from 'src/app/rest.service';
import { Router } from '@angular/router';
import { DataCarrierService } from 'src/app/services/data-carrier.service';
import { ConfigService } from 'src/app/services/config-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public elem: any;
  public dark: boolean = false;
  public EmailVerificationCheck: boolean = false;
  api_url: string;
  private _restInvoker: any;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.eRef.nativeElement.contains(event.target)) {
      //this.text = "clicked inside";
    } else {
      let ua = navigator.userAgent;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
        if(!this.navServices.collapseSidebar){
          //this.sidebarToggle();
          //this.navServices.collapseSidebar = true;
        }
      }
    }
  }
  
  constructor(public layout: LayoutService,
    public navServices: NavService,  private eRef: ElementRef,
    @Inject(DOCUMENT) private document: any, private _router: Router, 
    public _dataCarrier: DataCarrierService, private _configService: ConfigService,
    public toster: ToastrService, @Inject(RestInvokerService) restInvoker
  ) {
    this._restInvoker = restInvoker;
  }

  ngOnInit() {
    this.elem = document.documentElement;
    this.sessionChecker();
    if(localStorage.getItem("emailVerified") == "false"){      
      this.EmailVerificationCheck = true;
    }
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu  = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    this.dark = !this.dark;
  }

  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  sessionChecker(){
    //Data to be sent to API
    var payload = {};
    this.api_url = "api/user/me/";

    try {
      var propData = this._restInvoker.SendHttpAsync(this.api_url, "GET", payload, null, null, "N", true)
        .subscribe(
          data => {
            if(data.emailVerified){
              localStorage.setItem("emailVerified","true");    
              this.EmailVerificationCheck = false;          
            }
          },
          error => {
                        if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
            if (error.status == 400) {
              this._configService.exceptionLogout(null);
            }
          }
        );
    }
    catch (ex) {
      //console.log(ex);
    }
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }


}
