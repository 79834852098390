import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { RestInvokerService } from 'src/app/rest.service';
import { AppVariables } from 'src/app/services/app-variables';
import { DataCarrierService } from 'src/app/services/data-carrier.service';
import { ConfigService } from 'src/app/services/config-service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  private _restInvoker: any;
  public email: string;
  public password: string;
  public api_url: string;

  constructor(private _activatedRoute: ActivatedRoute, public authService: AuthService, private _formBuilder: FormBuilder, private _appvariable: AppVariables,
    private _router: Router, public _dataCarrier: DataCarrierService, private _configService: ConfigService,
    @Inject(RestInvokerService) restInvoker, public toster: ToastrService,public ngZone: NgZone,) {
    this._restInvoker = restInvoker;
  }

  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(250)]],
      password: ['', [Validators.required]],
      rememberme: [false],
    });

    var pageInit = this._activatedRoute.snapshot.queryParams['action'];
    if(pageInit == "logout" && localStorage.getItem("UserId") == null){
      localStorage.setItem("UserId", "#");
      location.reload();
    }
  }

  showPassword() {
    this.show = !this.show;
  }

  // Login With Google
  loginGoogle() {
    this.authService.GoogleAuth();
  }

  // Login With Twitter
  loginTwitter(): void {
    this.authService.signInTwitter();
  }

  // Login With Facebook
  loginFacebook() {
    this.authService.signInFacebok();
  }

  // Simple Login
  login(l) {
    this.onLogin();
    // if(this.loginForm.value['email'] == "manager@gmail.com"){
    //   localStorage.setItem('usertype', "manager");
    //   this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);
    // }else{
    //   localStorage.setItem('usertype', "user");
    //   this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);
    // }
  }

  onLogin() {
      if(this.loginForm.get('rememberme').value){
        localStorage.setItem("RememberMe", "Y");
      }else{
        localStorage.setItem("RememberMe", "N");
      }

      if (this.loginForm.invalid) {
        Swal.fire(
          'Invalid Data',
          'Please enter your email and password and submit!',
          'error'
        );
        return;
      }

      this.email = this.loginForm.get('email').value;
      this.password = this.loginForm.get('password').value;

      //Data to be sent to API
      var payload = {
        "email": this.email,
        "password": this.password
      };

      this.api_url = "api/auth/login/";

      try {
        var loginUser = this._restInvoker.SendHttpAsync(this.api_url, "POST", payload, null, null, "N", true)
          .subscribe(
            data => {
              if (data != null && data != undefined) {
                if (data.email != null && (data.id != null || data.id != undefined || data.id != "")) {
                  localStorage.setItem("Token", data.accessToken);
                  localStorage.setItem("UserId", data.id);
                  localStorage.setItem("UserFName", data.firstName);
                  localStorage.setItem("UserLName", data.lastName);
                  localStorage.setItem("DisplayName", data.displayName);
                  localStorage.setItem("EntityType", data.entityType);
                  localStorage.setItem("EmailId", data.email);
                  localStorage.setItem("Mobile", data.mobile);
                  localStorage.setItem("type", data.type);
                  localStorage.setItem("UserType", (data.type == "Owner" ? "owner" : "tenant"));
                  localStorage.setItem("EntityRole", data.role);   //'Owner';//'Manager';//'Tenant';
                  localStorage.setItem("emailVerified", data.emailVerified);
                  localStorage.setItem("mobileVerified", data.mobileVerified);

                  // this.ngZone.run(() => {
                  //   this._router.navigate(['/property/owner/default']);
                  // });
                  this._router.navigate(['/property/owner/dashboard'], {
                    replaceUrl: true,
                    queryParams: {
                      uid: data.id,
                    }
                  });
                } else {
                  if (data.Message != null) {
                    Swal.fire({
                      title: data.response.Message,
                      icon: 'error',
                      showConfirmButton: false,
                      timer: 6000
                    });
                    return false;
                  }
                }
              } else {
                this._configService.exceptionLogout("UnknownHack");
              }
            },
            error => {
            if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
              if (error.status == 400) {
                this._configService.exceptionLogout(null);
                try {
                  if (error.error.message != null && error.error.message == "User not active") {
                    this.toster.error('Entered User is not Active.');
                    return false;
                  }else if (error.error.message != null) {
                    this.toster.error('You have enter Wrong Email or Password.');
                    return false;
                  }else {
                    Swal.fire({
                      title: 'Entered Invalid Data. Please re-enter!',
                      icon: 'error',
                      showConfirmButton: true
                    });
                    return false;
                  }
                }
                catch (err) {
                  let errorMsg = error.error.message;
                  Swal.fire({
                    title: 'Oops! Entered data is invalid.',
                    text: errorMsg,
                    icon: 'error',
                    showConfirmButton: true
                  }).then((result) => {
                    this.loginForm.reset();
                  });
                }
              }
            }
          );
      }
      catch (ex) {
        //console.log(ex);
      }
  }

}
