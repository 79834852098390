import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RestInvokerService } from 'src/app/rest.service';
import { ConfigService } from 'src/app/services/config-service';
import { DataCarrierService } from 'src/app/services/data-carrier.service';
import Swal from 'sweetalert2';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'app-customizer',
  templateUrl: './customizer.component.html',
  styleUrls: ['./customizer.component.scss']
})
export class CustomizerComponent implements OnInit {

  public screenwidth: any = window.innerWidth;
  public customizer: boolean = false;
  public layoutType: string = 'ltr';
  public sidebarType: string = 'compact-wrapper';
  public sidebarSetting: string = 'default-sidebar';
  public MIXLayout: string = 'default';

  public primary_color: string = '#0d4d0d';
  public secondary_color: string = '#f73164';

  public api_url: string = '';
  public fbSubject: string = '';
  public fbDescription: string = '';
  public emojiSel: string = '';
  private _restInvoker: any;

  constructor(public layout: LayoutService, private _router: Router, public _dataCarrier: DataCarrierService, private _configService: ConfigService,
    @Inject(RestInvokerService) restInvoker, public toster: ToastrService, private modalService: NgbModal) {
    this._restInvoker = restInvoker;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = event.target.innerWidth;
  }

  ngOnInit() { }

  // Open Modal
  openModal(popup) {
    this.modalService.open(popup, { backdropClass: 'dark-modal', centered: true });
  }

  // Open customizer
  Customizer(emotion) {
    this.customizer = !this.customizer;
    this.emojiSel = emotion;

    if(emotion == "Angry"){
      this.fbSubject = "I'm Angry";
    }else if(emotion == "Sad"){
      this.fbSubject = "I'm Sad";
    }else if(emotion == "No Expression"){
      this.fbSubject = "I'm OK";
    }else if(emotion == "Happy"){
      this.fbSubject = "I'm Happy!";
    }else if(emotion == "Love it"){
      this.fbSubject = "I Love it!";
    }
    //this.toster.error("Functionality is yet to be implemented!");
  }

  // Customize Layout Type
  customizeLayoutType(val) {
    this.layoutType = val;
    this.layout.config.settings.layout_type = val;
    if(val == 'rtl') {
      document.getElementsByTagName('html')[0].setAttribute('dir', val);
    } else {
      document.getElementsByTagName('html')[0].removeAttribute('dir');
    }
  }

  // Customize Sidebar Type
  customizeSidebarType(val) {
    this.sidebarType = val;
    this.layout.config.settings.sidebar.type = val;
    this.layout.config.settings.sidebar.body_type = val == 'compact-wrapper' ? 'sidebar-icon' : 'horizontal-menu';
  }

  // Customize Sidebar Setting
  customizeSidebarSetting(val) {
    this.sidebarSetting = val;
    this.layout.config.settings.sidebar_type = val;
  }

  // Customize Mix Layout
  customizeMixLayout(val) {
    this.MIXLayout = val;
    this.layout.config.settings.layout_version = val;
  }

  applyColor() {
    document.documentElement.style.setProperty('--theme-deafult', this.primary_color);
    document.documentElement.style.setProperty('--theme-secondary', this.secondary_color);
    this.layout.config.color.primary_color = this.primary_color;
    this.layout.config.color.secondary_color = this.secondary_color;
  }

  resetColor() {
    document.documentElement.style.setProperty('--theme-deafult', '#7366ff');
    document.documentElement.style.setProperty('--theme-secondary', '#f73164');
    (<HTMLInputElement>document.getElementById('ColorPicker1')).value = '#7366ff';
    (<HTMLInputElement>document.getElementById('ColorPicker2')).value = '#f73164';
    this.layout.config.color.primary_color = '#7366ff';
    this.layout.config.color.secondary_color = '#f73164';
  }

  resetFeedback(){
    this.fbSubject = '';
    this.fbDescription = '';
  }

  createFeedback(){
    var submitURL = window.location.href;
    var payload = {
      "Subject": this.fbSubject,
      "Comment": this.fbDescription,
      "Imoji": this.emojiSel,
      "Page": submitURL
    }
    this.api_url = "api/feedback/";
    try {
      var propData = this._restInvoker.SendHttpAsync(this.api_url, "POST", payload, null, null, "N", true)
        .subscribe(
          data => {
            if (data != null && data != undefined) {
              this.toster.success('Thanks for your feedback!');
              this.customizer = !this.customizer;
            } else {
              if (data.Message != null) {
                Swal.fire({
                  title: data.response.Message,
                  icon: 'error',
                  showConfirmButton: false,
                  timer: 6000
                });
                return false;
              }
            }
          },
          error => {
            if (error.status == 401) this._configService.exceptionLogout('401');
            if (error.status == 500) this._configService.exceptionLogout('500');
            if (error.status == 403) this._configService.exceptionLogout('403');
            if (error.status == 400) {
              this._configService.exceptionLogout(null);
            }
          }
        );
    }
    catch (ex) {
      //console.log(ex);
    }
  }
}
